import React from 'react';
import { CircularProgress, Center, Box } from '@chakra-ui/react';

const Loading = () => {
  return (
    <Center h="100vh">
      <Box textAlign="center">
        <CircularProgress
          isIndeterminate
          color="blue.500"
          size="8rem"
          thickness="8px"
          marginBottom="4"
        />
        <div>Loading...</div>
      </Box>
    </Center>
  );
};

export default Loading;
