import { useCustomToast } from "../siswa/toast";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import axios from "../../helper/axios";

const {
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Box,
  Select,
} = require("@chakra-ui/react");

export const ModalAdd = ({ data, reload, setReload }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [opt, setOpt] = useState([]);
  const [loading, setLoading] = useState(false);
  const customToast = useCustomToast();
  const token = localStorage.getItem("token");

  const getData = async () => {
    try {
      const { data } = await axios.get("/program/ref-program", {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setOpt(data.program);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (value) => {
    try {
      setLoading(true);
      await axios.post("/program", value, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      customToast({
        title: "Success",
        description: "Data Added",
        status: "success",
      });
      setReload(!reload)
      onClose();
    } catch (err) {
      console.log(err);
      setLoading(false);
      customToast({
        title: "Warning",
        description: `${err.response.data}`,
        status: "warning",
      });
    }
  };

  useEffect(() => {
    getData();
  }, [reload]);

  return (
    <>
      <Button
        size={{ lg: "sm", base: "xs" }}
        colorScheme="blue"
        onClick={onOpen}
      >
        Tambah Program
      </Button>

      <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Tambah Program</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="10px">
              anda akan menambahkan program ke tahun pelajaran{" "}
              {data.tahun_pelajaran} semester {data.semester}
            </Text>
            <Formik
              initialValues={{
                program: "",
              }}
              validationSchema={Yup.object().shape({
                program: Yup.string().required("program is required"),
              })}
              onSubmit={(data, action) => {
                handleSubmit(data);
                action.resetForm();
              }}
            >
              {({ values, dirty, setFieldValue }) => {
                return (
                  <Flex
                    as={Form}
                    justifyContent="center"
                    alignItems="start"
                    gap="2"
                    m="20px 0"
                  >
                    <Box w="100%">
                      <Select
                        placeholder=" ~~ pilih program ~~"
                        defaultValue=""
                        name="program"
                        onChange={(e) => setFieldValue("program", e.target.value)}
                      >
                        {opt
                          .filter((v) => v.kela == null)
                          .map((item, index) => {
                            return (
                              <option key={index} value={item.id}>
                                {item.nama}
                              </option>
                            );
                          })}
                      </Select>
                      <ErrorMessage
                        component="div"
                        name="program"
                        style={{ color: "red", fontSize: "12px" }}
                      />
                    </Box>
                    <Button
                      colorScheme="blue"
                      type="submit"
                      isLoading={loading}
                    >
                      Add
                    </Button>
                  </Flex>
                );
              }}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
