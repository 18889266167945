import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Select,
  Text,
} from "@chakra-ui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { lazy, Suspense } from "react";
import Loading from "../loading";
import { BreadcrumbComp } from "../breadCump";
import { useSelector } from "react-redux";

const TableSiswa = lazy(() => import("./table"));

export const DaftarSiswaBaru = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get("page") || 1;
  const tingkat = searchParams.get("tingkat") || "";
  const search = searchParams.get("search") || "";
  const status = searchParams.get("status") || "";
  const user = useSelector((state) => state.userSlice.value);
  return (
    <Flex w="90%" gap="5" direction="column">
      <Flex
        direction="column"
        gap="5"
        position={{ lg: "sticky", base: "none" }}
        top="10vh"
        zIndex="10"
        bgColor="gray.200"
        pb="4"
      >
        <BreadcrumbComp
          menu={"Siswa"}
          subMenu={[
            {
              desc: "Daftar Siswa",
              link: "",
            },
          ]}
        />
        <Flex justifyContent="space-between" alignItems="center">
          <Heading fontSize={{ md: "30px", base: "20px" }}>
            Daftar Siswa Baru
          </Heading>
        </Flex>
        <Flex
          gap="2"
          direction={{ lg: "row", base: "column" }}
          alignItems="center"
          borderTop="1px solid gray"
          borderBottom="1px solid gray"
          p="5"
        >
          <Select
            flex={1}
            bgColor="white"
            borderRadius="2xl"
            fontSize="14px"
            value={status}
            defaultValue={status}
            defaultChecked={status}
            onChange={(e) =>
              navigate(
                `../daftar-siswa-baru/?search=${search}&page=1&tingkat=${tingkat}&status=${e.target.value}`
              )
            }
          >
            <option defaultChecked value="">
              Semua Status
            </option>
            <option value="pending">Pending</option>
            <option value="process">Process</option>
            <option value="print">Print</option>
            <option value="done">Done</option>
          </Select>
          <Select
            flex={1}
            bgColor="white"
            borderRadius="2xl"
            defaultValue={tingkat}
            fontSize="14px"
            onChange={(e) =>
              navigate(
                `../daftar-siswa-baru/?search=${search}&page=1&tingkat=${e.target.value}&status=${status}`
              )
            }
          >
            <option value="">Semua Tingkat/Kelas</option>
            <option value="1">Mts</option>
            <option value="2">Ma</option>
          </Select>
          <Input
            flex={{ lg: 1, base: "none" }}
            placeholder="cari nama / NPS"
            defaultValue={search}
            type="search"
            bgColor="white"
            borderRadius="2xl"
            fontSize="14px"
            border="none"
            onChange={(e) =>
              navigate(
                `../daftar-siswa-baru/?search=${e.target.value}&page=1&tingkat=${tingkat}&status=${status}`
              )
            }
          />
          <Flex fontSize="14px" alignItems="center" gap="2">
            <Text>Tahun Ajaran </Text>
            <Box bgColor="green.100" p="2" borderRadius="xl">
              {user.tahun_pelajaran}
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <Suspense fallback={<Loading />}>
        <TableSiswa />
      </Suspense>
    </Flex>
  );
};
