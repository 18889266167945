import { Flex, Heading, Text } from "@chakra-ui/react";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Biodata } from "./biodata";
//   import { BioOrtu } from "./ortudata";
//   import { FormAlamat } from "./alamatdata";
//   import { BerkasSiswa } from "./berkas";
import axios from "../../../helper/axios";
import { BreadcrumbComp } from "../../breadCump";
import { FormAlamat } from "./alamat";

export const DetailGuru = () => {
  const token = localStorage.getItem("token");
  const { npy } = useParams();
  const [active, setActive] = useState("Data Guru");
  const [data, setData] = useState({});
  const [reload, setReload] = useState(true);
  const getData = async () => {
    try {
      const { data } = await axios.get(`/teachers/${npy}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setData(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
    window.scroll(0, 0);
  }, [reload]);

  return (
    <Flex w="90%" gap="1" direction="column">
      <Flex
        direction="column"
        gap="5"
        position="sticky"
        top="10vh"
        zIndex="10"
        bgColor="gray.200"
        pb="4"
      >
        <BreadcrumbComp
          menu={"Guru"}
          subMenu={[
            {
              desc: "Daftar Guru",
              link: "../daftar-guru",
            },
            {
              desc: "Detail Guru",
              link: "",
            },
          ]}
        />
        <Flex gap="3" alignItems="center">
          <Heading fontSize={{ lg: "35px", base: "20px" }}>Detail Guru</Heading>
          <Heading fontWeight="none" fontSize={{ lg: "25px", base: "16px" }}>
            {data?.Nama}
          </Heading>
        </Flex>
        <Flex
          bgColor="white"
          w="full"
          borderRadius="xl"
          cursor="pointer"
          fontSize={{ lg: "18px", base: "12px" }}
        >
          <Flex
            key={1}
            h="60px"
            justifyContent="center"
            alignItems="center"
            p="2"
            flex={1}
            bgColor={active === "Data Guru" ? "blue.400" : "transparent"}
            color={active === "Data Guru" ? "white" : "black"}
            borderRadius="xl"
            onClick={() => setActive("Data Guru")}
          >
            <Text
              textAlign="center"
              fontSize={{ md: "14px", base: "10px" }}
              fontWeight="bold"
            >
              Data Guru
            </Text>
          </Flex>
          <Flex
            key={2}
            h="60px"
            justifyContent="center"
            alignItems="center"
            p="2"
            flex={1}
            bgColor={active === "Data Alamat" ? "blue.400" : "transparent"}
            color={active === "Data Alamat" ? "white" : "black"}
            borderRadius="xl"
            onClick={() => setActive("Data Alamat")}
          >
            <Text
              textAlign="center"
              fontSize={{ md: "14px", base: "10px" }}
              fontWeight="bold"
            >
              Data Alamat
            </Text>
          </Flex>
          {/* <Flex
              key={3}
              h="60px"
              justifyContent="center"
              alignItems="center"
              p="2"
              flex={1}
              bgColor={active === "Berkas" ? "blue.400" : "transparent"}
              color={active === "Berkas" ? "white" : "black"}
              borderRadius="xl"
              onClick={() => setActive("Berkas")}
            >
              <Text
                textAlign="center"
                fontSize={{ md: "14px", base: "10px" }}
                fontWeight="bold"
              >
                Berkas
              </Text>
            </Flex> */}
        </Flex>
      </Flex>
      {active === "Data Guru" ? (
        <Biodata
          img={data?.berkas?.filter((item) => item.jenis === "pas")[0]}
          nama={data?.Nama}
          nik={data?.NIK}
          KotaLahir={data?.guru_biodata?.KotaLahir}
          TglLahir={data?.guru_biodata?.TglLahir}
          jk={data?.JK}
          gelarDepan={data?.guru_biodata?.GelarDepan}
          gelarBelakang={data?.guru_biodata?.GelarBelakang}
          reload={reload}
          setReload={setReload}
        />
      ) : // ) : active === "Data Orang Tua" ? (
      //   <BioOrtu
      //     Nama_ayah={data?.siswa_ayah?.Nama}
      //     NIK_ayah={data?.siswa_ayah?.NIK}
      //     TglLahir_ayah={data?.siswa_ayah?.TglLahir}
      //     KotaLahir_ayah={data?.siswa_ayah?.KotaLahir}
      //     NoHp_ayah={data?.siswa_ayah?.NoHP}
      //     Pendapatan_ayah={data?.siswa_ayah?.pendapatanId}
      //     Pendidikan_ayah={data?.siswa_ayah?.pendidikanId}
      //     Pekerjaan_ayah={data?.siswa_ayah?.pekerjaanId}
      //     Nama_ibu={data?.siswa_ibu?.Nama}
      //     NIK_ibu={data?.siswa_ibu?.NIK}
      //     TglLahir_ibu={data?.siswa_ibu?.TglLahir}
      //     KotaLahir_ibu={data?.siswa_ibu?.KotaLahir}
      //     NoHp_ibu={data?.siswa_ibu?.NoHP}
      //     Pendapatan_ibu={data?.siswa_ibu?.pendapatanId}
      //     Pendidikan_ibu={data?.siswa_ibu?.pendidikanId}
      //     Pekerjaan_ibu={data?.siswa_ibu?.pekerjaanId}
      //     Nama_wali={data?.siswa_wali?.Nama}
      //     NIK_wali={data?.siswa_wali?.NIK}
      //     TglLahir_wali={data?.siswa_wali?.TglLahir}
      //     KotaLahir_wali={data?.siswa_wali?.KotaLahir}
      //     NoHp_wali={data?.siswa_wali?.NoHP}
      //     Pendapatan_wali={data?.siswa_wali?.pendapatanId}
      //     Pendidikan_wali={data?.siswa_wali?.pendidikanId}
      //     Pekerjaan_wali={data?.siswa_wali?.pekerjaanId}
      //   />
      active === "Data Alamat" ? (
        <FormAlamat
          Rt={data?.guru_alamat?.Rt}
          Rw={data?.guru_alamat?.Rw}
          Alamat={data?.guru_alamat?.Alamat}
          KodePos={data?.guru_alamat?.KodePos}
          provinsiId={data?.guru_alamat?.provinsiId}
          kabupatenId={data?.guru_alamat?.kabupatenId}
          kecamatanId={data?.guru_alamat?.kecamatanId}
          desaId={data?.guru_alamat?.desaId}
          reload={reload}
          setReload={setReload}
        />
      ) : // ) : active === "Berkas" ? (
      //   <BerkasSiswa
      //     pas_img={data?.berkas?.filter((item) => item.jenis === "pas")[0]}
      //     kk_img={data?.berkas?.filter((item) => item.jenis === "kk")[0]}
      //     akta_img={data?.berkas?.filter((item) => item.jenis === "akta")[0]}
      //     ktp_ayah_img={
      //       data?.berkas?.filter((item) => item.jenis === "ktp-ayah")[0]
      //     }
      //     ktp_ibu_img={
      //       data?.berkas?.filter((item) => item.jenis === "ktp-ibu")[0]
      //     }
      //     ktp_wali_img={
      //       data?.berkas?.filter((item) => item.jenis === "ktp-wali")[0]
      //     }
      //     kesehatan_img={
      //       data?.berkas?.filter((item) => item.jenis === "kesehatan")[0]
      //     }
      //     pernyataan_siswa_img={
      //       data?.berkas?.filter((item) => item.jenis === "pernyataan-siswa")[0]
      //     }
      //     pernyataan_wali_img={
      //       data?.berkas?.filter((item) => item.jenis === "pernyataan-wali")[0]
      //     }
      //     reload={reload}
      //     setReload={setReload}
      //   />
      null}
    </Flex>
  );
};
