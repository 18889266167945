import { Button, Icon, Flex } from "@chakra-ui/react";
import { AiOutlineCloudDownload } from "react-icons/ai";
import axios from "../../../helper/axios";
import { useState } from "react";

export const DownloadAppsheet = () => {
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const getData = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`/class/synchronize`, {
        responseType: "blob",
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      const url = window.URL.createObjectURL(new Blob([data]));
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `daftar-appsheet.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      setLoading(false)
    } catch (err) {
      console.log(err);
      setLoading(false)
    }
  };
  return (
    <Button
      as={Flex}
      onClick={getData}
      size={{ lg: "sm", base: "xs" }}
      colorScheme="blue"
      gap={2}
      isLoading={loading}
      cursor='pointer'
    >
      Appsheet
      <Icon as={AiOutlineCloudDownload} w="5" h="5" />
    </Button>
  );
};
