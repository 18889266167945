import {
  Badge,
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "../../../helper/axios";
import { CiMenuKebab } from "react-icons/ci";
import { MoveProgram } from "./move";
import { useCustomToast } from "../../siswa/toast";

const TableProgramSiswa = ({ tapel, reload }) => {
  const token = localStorage.getItem("token");
  const [data, setData] = useState([]);
  const { program } = useParams();
  const customToast = useCustomToast()

  const getData = async () => {
    try {
      const { data } = await axios.get(`/program/${program}/student`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setData(data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async (data) => {
    try {
      await axios.patch(
        "/program/delete",
        { id: data.id },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      customToast({
        title: "Success",
        description: "Data Updated",
        status: "success",
      });
    } catch (err) {
      customToast({
        title: "Warning",
        description: `${err.response.data}`,
        status: "warning",
      });
      console.log(err);
    }
  }

  useEffect(() => {
    getData();
    window.scroll(0, 0);
  }, [reload]);

  return (
    <Box mb="30px">
      <TableContainer>
        <Table variant="simple">
          <TableCaption>daftar siswa tahun ajaran {tapel}</TableCaption>
          <Thead fontSize="14px">
            <Tr>
              <Th>NO</Th>
              <Th display={{ md: "none", base: "table-cell" }}>Detail Siswa</Th>
              <Th display={{ md: "table-cell", base: "none" }}>NPS</Th>
              <Th display={{ md: "table-cell", base: "none" }}>Nama Siswa</Th>
              <Th display={{ sm: "table-cell", base: "none" }}>STATUS</Th>
            </Tr>
          </Thead>
          <Tbody fontSize="14px" bgColor="white">
            {data.map((item, index) => {
              return (
                <Tr key={index}>
                  <Td maxW="5px">{index + 1}</Td>
                  <Td display={{ md: "none", base: "table-cell" }}>
                    <Flex direction="column" fontSize="14px">
                      <Text fontSize="14px" fontWeight="bold">
                        {item.siswa?.siswa_biodata?.Nama}
                      </Text>
                      <Text fontSize="12px">
                        {item.siswa?.siswa_biodata?.NPS}
                      </Text>
                      <Badge
                        display={{ sm: "none", base: "flex" }}
                        w="fit-content"
                        color="green"
                        fontSize="10px"
                        borderRadius="md"
                      >
                        AKTIF
                      </Badge>
                    </Flex>
                  </Td>
                  <Td display={{ md: "table-cell", base: "none" }}>
                    {item.siswa?.siswa_biodata?.NPS}
                  </Td>
                  <Td display={{ md: "table-cell", base: "none" }}>
                    {item.siswa?.siswa_biodata?.Nama}
                  </Td>
                  <Td display={{ sm: "table-cell", base: "none" }}>
                    <Badge
                      w="fit-content"
                      color="green"
                      fontSize="10px"
                      borderRadius="md"
                    >
                      AKTIF
                    </Badge>
                  </Td>
                  <Td>
                    <Menu>
                      <MenuButton
                        size={{ lg: "sm", base: "xs" }}
                        as={Button}
                        bgColor="transparent"
                      >
                        <CiMenuKebab />
                      </MenuButton>
                      <MenuList>
                        <MoveProgram student={item} />
                        <MenuItem color={'red'} onClick={() => handleDelete(item)}>Hapus</MenuItem>
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TableProgramSiswa;
