import {
  Box,
  Button,
  Flex,
  Icon,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";
import { AiOutlineCloudUpload } from "react-icons/ai";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage } from "formik";
import { useState } from "react";
import { useCustomToast } from "../toast";
import axios from "../../../helper/axios";

export const FileUpload = () => {
  const [loading, setLoading] = useState(false);
  const customToast = useCustomToast();
  const token = localStorage.getItem("token");
  const validationSchema = Yup.object().shape({
    excel: Yup.string().required("excel is required"),
  });

  const handleSubmit = async (data) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("excel", data.excel);

      const response = await axios.post("/students/excel-upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      customToast({
        title: "Success",
        description: "Data Added",
        status: "success",
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
      customToast({
        title: "Warning",
        description: `${err.response.data}`,
        status: "warning",
      });
    }
  };

  return (
    <Popover>
      <PopoverTrigger>
        <Button colorScheme="blue" variant="unstyled">
          <Icon as={AiOutlineCloudUpload} w="5" h="5" />
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            <Formik
              initialValues={{
                excel: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(data, action) => {
                handleSubmit(data);
                action.resetForm();
              }}
            >
              {({ values, dirty, setFieldValue }) => {
                return (
                  <Flex
                    as={Form}
                    justifyContent="center"
                    alignItems="start"
                    gap="2"
                    mt="6"
                    mb="2"
                  >
                    <Box>
                      <Input
                        type="file"
                        name="excel"
                        accept=".xlsx, .xls, .xlsm"
                        onChange={(e) => {
                          setFieldValue("excel", e.target.files[0]);
                        }}
                      />
                      <ErrorMessage
                        component="div"
                        name="excel"
                        style={{ color: "red", fontSize: "12px" }}
                      />
                    </Box>
                    <Button
                      isLoading={loading}
                      colorScheme="blue"
                      type="submit"
                    >
                      <Icon as={AiOutlineCloudUpload} w="5" h="5" />
                    </Button>
                  </Flex>
                );
              }}
            </Formik>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
