import { Icon, Button, Flex } from "@chakra-ui/react";
import { AiOutlineCloudDownload } from "react-icons/ai";
import axios from "../../../helper/axios";
import { useLocation } from "react-router-dom";
import { useState } from "react";

export const DownloadSiswa = () => {
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tingkat = searchParams.get("tingkat") || "";
  const search = searchParams.get("search") || "";
  const status = searchParams.get("status") || "aktif";
  const getExcel = async () => {
    try {
      setLoading(true)
      const { data } = await axios.get(
        `/students/excell?tingkat=${tingkat}&nama=${search}&nps=${search}`,
        {
          responseType: "blob",
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `daftar-siswa.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      setLoading(false)
    } catch (err) {
      console.log(err);
      setLoading(false)
    }
  };

  if (status !== "aktif") {
    return null;
  }
  return (
    <Button
      as={Flex}
      onClick={getExcel}
      size={{ lg: "sm", base: "xs" }}
      colorScheme="blue"
      gap={2}
      isLoading={loading}
      cursor='pointer'
    >
      Siswa
      <Icon as={AiOutlineCloudDownload} w="5" h="5" />
    </Button>
  );
};
