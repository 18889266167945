import {
  Avatar,
  Button,
  Flex,
  FormLabel,
  Heading,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, useParams } from "react-router-dom";
import axios from "../../../helper/axios";
import { useCustomToast } from "../../siswa/toast";

const FieldInput = ({ label, placeholder, defaultValue, name, ...props }) => {
  return (
    <FormLabel w="inherit">
      <FormLabel fontWeight={"normal"} fontSize="14px" m="2px">
        {label}
      </FormLabel>
      <Field
        name={name}
        as={Input}
        fontSize="14px"
        defaultValue={defaultValue}
        placeholder={placeholder}
        {...props}
      />
      <ErrorMessage
        component="div"
        name={name}
        style={{ color: "red", fontSize: "12px" }}
      />
    </FormLabel>
  );
};

export const Biodata = ({
  nama,
  jk,
  nik,
  KotaLahir,
  TglLahir,
  img,
  gelarDepan,
  gelarBelakang,
  reload,
  setReload
}) => {
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const customToast = useCustomToast();
  const { npy } = useParams();
  const validationSchema = Yup.object().shape({
    Nama: Yup.string().required("nama is required"),
    JK: Yup.string().required("jenis kelamin is required"),
    NIK: Yup.string()
      .required("nik is required")
      .length(16, "NIK must be exactly 16 characters"),
    KotaLahir: Yup.string().required("kota lahir is required"),
    TglLahir: Yup.date().required("tanggal lahir is required"),
  });

  const handleSubmit = async (value) => {
    try {
      setLoading(true);
      await axios.patch(`/teachers/${npy}`, value, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      setReload(!reload)
      customToast({
        title: "Success",
        description: "Data Updated",
        status: "success",
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
      customToast({
        title: "Warning",
        description: `${err.response.data}`,
        status: "warning",
      });
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <Formik
      initialValues={{
        Nama: nama || "",
        GelarDepan: gelarDepan || "",
        GelarBelakang: gelarBelakang || "",
        JK: jk || "",
        NIK: nik || "",
        KotaLahir: KotaLahir || "",
        TglLahir: TglLahir || "",
      }}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(data, action) => {
        handleSubmit(data);
      }}
    >
      {({ values, dirty, setFieldValue }) => {
        return (
          <Flex direction="column" bgColor="white" p="5" borderRadius="xl">
            <Form>
              <Flex
                gap="8"
                direction={{ lg: "row", base: "column" }}
                alignItems={{ lg: "none", base: "center" }}
              >
                <Avatar
                  size="2xl"
                  src={`https://adm.api.darussalamkasomalang.com/public/${img?.url}`}
                  ml={{ lg: "5", base: "0" }}
                />
                <Flex
                  direction={{ lg: "row", base: "column" }}
                  justifyContent="space-evenly"
                  w="100%"
                >
                  <FieldInput
                    placeholder="gelar depan"
                    defaultValue={values.GelarDepan}
                    label="Gelar Depan"
                    name="GelarDepan"
                  />
                  <FieldInput
                    placeholder="nama lengkap"
                    defaultValue={values.Nama}
                    label="Nama Lengkap"
                    name="Nama"
                  />
                  <FieldInput
                    placeholder="gelar belakang"
                    defaultValue={values.GelarBelakang}
                    label="Gelar Belakang"
                    name="GelarBelakang"
                  />
                </Flex>
              </Flex>
              <Flex w="100%" direction={{ lg: "row", base: "column" }}>
                <FieldInput
                  placeholder="nik"
                  defaultValue={values.NIK}
                  label="NIK"
                  name="NIK"
                />
              </Flex>
              <Flex w="100%" direction={{ lg: "row", base: "column" }}>
                <FieldInput
                  placeholder="tempat lahir"
                  defaultValue={values.KotaLahir}
                  label="Tempat lahir"
                  name="KotaLahir"
                />
                <FieldInput
                  placeholder="tanggal lahir"
                  defaultValue={values.TglLahir}
                  type="date"
                  label="Tanggal lahir"
                  name="TglLahir"
                />
              </Flex>
              <Flex direction="column" gap="2" m="4">
                <Heading fontSize="14px" fontWeight="normal">
                  Jenis Kelamin
                </Heading>
                <RadioGroup
                  name="JK"
                  fontSize="14px"
                  isDisabled
                  value={values.JK}
                >
                  <Stack spacing={5} direction="row">
                    <Radio value="L">
                      <Text fontSize="14px">Laki-laki</Text>
                    </Radio>
                    <Radio value="P">
                      <Text fontSize="14px">Perempuan</Text>
                    </Radio>
                  </Stack>
                </RadioGroup>
              </Flex>
              <Flex justifyContent="space-between" mt="4">
                <Button as={Link} to="../daftar-guru">
                  Kembali
                </Button>
                <Button
                  isLoading={loading}
                  type="submit"
                  isDisabled={!dirty}
                  colorScheme="blue"
                >
                  Simpan
                </Button>
              </Flex>
            </Form>
          </Flex>
        );
      }}
    </Formik>
  );
};
