import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Icon,
} from "@chakra-ui/react";
import { AiOutlineRight, AiFillHome } from "react-icons/ai";
import { Link } from "react-router-dom";

export const BreadcrumbComp = ({ menu, subMenu }) => {
  return (
    <Flex
      alignItems="center"
      gap={{ md: "4", base: "2" }}
      bgColor="gray.300"
      w="fit-content"
      borderRadius="md"
    >
      <Flex
        borderRadius="md"
        w="7"
        h="7"
        justifyContent="center"
        bgColor="white"
        alignItems="center"
      >
        <Icon as={AiFillHome} w="3" h="3" color="blue.500" />
      </Flex>
      <Breadcrumb
        spacing="8px"
        separator={<AiOutlineRight color="gray.500" fontSize="12px" />}
      >
        <BreadcrumbItem>
          <BreadcrumbLink fontSize="12px">{menu}</BreadcrumbLink>
        </BreadcrumbItem>
        {subMenu.map((item, idx) => {
          return (
            <BreadcrumbItem key={idx}>
              <BreadcrumbLink fontSize="12px" mr={subMenu.length - 1 == idx ? "5" : "0"} as={Link} to={item.link}>
                {item.desc}
              </BreadcrumbLink>
            </BreadcrumbItem>
          );
        })}
      </Breadcrumb>
    </Flex>
  );
};
