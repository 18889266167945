import {
  Box,
  Button,
  Flex,
  Icon,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { Link } from "react-router-dom";

export const TableReport = ({ data }) => {
  return (
    <Box mb="30px">
      <TableContainer>
        <Table variant="simple">
          <Thead fontSize="14px">
            <Tr>
              <Th>NO</Th>
              <Th display={{ md: "none", base: "table-cell" }}>Report</Th>
              <Th display={{ md: "none", base: "table-cell" }}>Excell</Th>
              <Th display={{ md: "table-cell", base: "none" }}>Tanggal</Th>
              <Th display={{ md: "table-cell", base: "none" }}>Total</Th>
              <Th display={{ md: "table-cell", base: "none" }}>Excell</Th>
            </Tr>
          </Thead>
          <Tbody fontSize="14px" bgColor="white">
            {data.map((item, index) => {
              return (
                <Tr key={index}>
                  <Td maxW="5px">{index + 1}</Td>
                  <Td display={{ md: "none", base: "table-cell" }}>
                    <Flex direction="column" fontSize="14px">
                      <Text fontSize="12px">{item?.Tanggal}</Text>
                      <Text fontSize="12px">{item?.Total}</Text>
                    </Flex>
                  </Td>
                  <Td display={{ md: "table-cell", base: "none" }}>
                    {item?.Tanggal}
                  </Td>
                  <Td display={{ md: "table-cell", base: "none" }}>
                    {item?.Total}
                  </Td>
                  <Td>
                    <Link to={`https://adm.api.darussalamkasomalang.com/public/${item?.File}`}>
                      <Button
                        as={Flex}
                        size={"xs"}
                        colorScheme="blue"
                        gap={2}
                        cursor={"pointer"}
                      >
                        Download
                        <Icon as={AiOutlineCloudDownload} w="5" h="5" />
                      </Button>
                    </Link>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};
