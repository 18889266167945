import {
  Button,
  Flex,
  FormLabel,
  Heading,
  Input,
  Select,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useParams, Link } from "react-router-dom";
import { useState } from "react";
import axios from "../../../helper/axios";
import { useCustomToast } from "../../siswa/toast";

const FieldInput = ({ label, placeholder, defaultValue, name, ...props }) => {
  return (
    <FormLabel w="inherit">
      <FormLabel fontWeight={"normal"} fontSize="14px" m="2px">
        {label}
      </FormLabel>
      <Field
        name={name}
        as={Input}
        fontSize="14px"
        defaultValue={defaultValue}
        {...props}
      />
      <ErrorMessage
        component="div"
        name={name}
        style={{ color: "red", fontSize: "12px" }}
      />
    </FormLabel>
  );
};

export const FormAlamat = ({
  Alamat,
  Rt,
  Rw,
  KodePos,
  desaId,
  kecamatanId,
  kabupatenId,
  provinsiId,
  reload,
  setReload
}) => {
  const customToast = useCustomToast();
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const { npy } = useParams();
  const [provinsi, setProvinsi] = useState([]);
  const [kabupaten, setKabupaten] = useState([]);
  const [kecamatan, setKecamatan] = useState([]);
  const [desa, setDesa] = useState([]);
  const [prov, setProv] = useState(provinsiId || 1);
  const [kab, setKab] = useState(kabupatenId || 1);
  const [kec, setKec] = useState(kecamatanId || 1);

  const validationSchema = Yup.object().shape({
    provinsiId: Yup.string().required("provinsi is required"),
    kabupatenId: Yup.string().required("kabupaten is required"),
    kecamatanId: Yup.string().required("kecamatan is required"),
    desaId: Yup.string().required("desa is required"),
    Rt: Yup.string().required("Rt is required"),
    Rw: Yup.string().required("Rw is required"),
    KodePos: Yup.string().required("Kode Pos is required"),
    Alamat: Yup.string().required("Alamat is required"),
  });

  const getProv = async () => {
    try {
      const { data } = await axios.get("/options/provinsi");
      setProvinsi(data.provinsi);
    } catch (err) {
      console.log(err);
    }
  };
  const getKab = async () => {
    try {
      const { data } = await axios.get(`/options/kabupaten/${prov}`);
      setKabupaten(data.kabupaten);
    } catch (err) {
      console.log(err);
    }
  };
  const getKec = async () => {
    try {
      const { data } = await axios.get(`/options/kecamatan/${kab}`);
      setKecamatan(data.kecamatan);
    } catch (err) {
      console.log(err);
    }
  };
  const getDes = async () => {
    try {
      const { data } = await axios.get(`/options/desa/${kec}`);
      setDesa(data.desa);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (value) => {
    try {
      setLoading(true);
      await axios.patch(`/teachers/${npy}/alamat`, value, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      setReload(!reload)
      customToast({
        title: "Success",
        description: "Data Updated",
        status: "success",
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
      customToast({
        title: "Warning",
        description: `${err.response.data}`,
        status: "warning",
      });
    }
  };

  useEffect(() => {
    getProv();
    getKab();
    getKec();
    getDes();
    window.scroll(0, 0);
  }, [prov, kab, kec]);

  return (
    <Formik
      initialValues={{
        Rt: Rt || "",
        Rw: Rw || "",
        Alamat: Alamat || "",
        KodePos: KodePos || "",
        provinsiId: provinsiId || null,
        kabupatenId: kabupatenId || null,
        kecamatanId: kecamatanId || null,
        desaId: desaId || null,
      }}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(data, action) => {
        handleSubmit(data);
      }}
    >
      {({ values, dirty, setFieldValue }) => {
        return (
          <Flex direction="column" bgColor="white" p="5" borderRadius="xl">
            <Form>
              <Heading fontSize="25px" m="4">
                Alamat
              </Heading>
              <Flex
                w="100%"
                borderTop="1px solid"
                borderColor="gray.200"
                pt="3"
                direction={{ lg: "row", base: "column" }}
              >
                <FormLabel w="inherit">
                  <FormLabel fontWeight={"normal"} fontSize="14px" m="2px">
                    Provinsi
                  </FormLabel>
                  <Select
                    fontSize="14px"
                    value={values.provinsiId}
                    placeholder="-- provinsi --"
                    name="provinsiId"
                    onChange={(e) => {
                      setFieldValue("provinsiId", e.target.value);
                      setFieldValue("kabupatenId", "");
                      setFieldValue("kecamatanId", "");
                      setFieldValue("desaId", "");
                      setProv(e.target.value);
                    }}
                  >
                    {provinsi?.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item?.Provinsi}
                        </option>
                      );
                    })}
                  </Select>
                  <ErrorMessage
                    component="div"
                    name="provinsiId"
                    style={{ color: "red", fontSize: "12px" }}
                  />
                </FormLabel>
                <FormLabel w="inherit">
                  <FormLabel fontWeight={"normal"} fontSize="14px" m="2px">
                    Kabupaten/Kota
                  </FormLabel>
                  <Select
                    fontSize="14px"
                    value={values.kabupatenId}
                    placeholder="-- kabupaten/kota --"
                    name="kabupatenId"
                    onChange={(e) => {
                      setFieldValue("kabupatenId", e.target.value);
                      setFieldValue("kecamatanId", "");
                      setFieldValue("desaId", "");
                      setKab(e.target.value);
                    }}
                  >
                    {kabupaten?.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item?.Kabupaten}
                        </option>
                      );
                    })}
                  </Select>
                  <ErrorMessage
                    component="div"
                    name="kabupatenId"
                    style={{ color: "red", fontSize: "12px" }}
                  />
                </FormLabel>
              </Flex>
              <Flex w="100%" direction={{ lg: "row", base: "column" }}>
                <FormLabel w="inherit">
                  <FormLabel fontWeight={"normal"} fontSize="14px" m="2px">
                    Kecamatan
                  </FormLabel>
                  <Select
                    fontSize="14px"
                    value={values.kecamatanId}
                    placeholder="-- kecamatan --"
                    name="kecamatanId"
                    onChange={(e) => {
                      setFieldValue("kecamatanId", e.target.value);
                      setFieldValue("desaId", "");
                      setKec(e.target.value);
                    }}
                  >
                    {kecamatan?.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item?.Kecamatan}
                        </option>
                      );
                    })}
                  </Select>
                  <ErrorMessage
                    component="div"
                    name="kecamatanId"
                    style={{ color: "red", fontSize: "12px" }}
                  />
                </FormLabel>
                <FormLabel w="inherit">
                  <FormLabel fontWeight={"normal"} fontSize="14px" m="2px">
                    Desa
                  </FormLabel>
                  <Select
                    fontSize="14px"
                    value={values.desaId}
                    placeholder="-- desa --"
                    name="desaId"
                    onChange={(e) => {
                      setFieldValue("desaId", e.target.value);
                    }}
                  >
                    {desa?.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item?.Desa}
                        </option>
                      );
                    })}
                  </Select>
                  <ErrorMessage
                    component="div"
                    name="desaId"
                    style={{ color: "red", fontSize: "12px" }}
                  />
                </FormLabel>
              </Flex>
              <Flex w="100%" direction={{ lg: "row", base: "column" }}>
                <FieldInput defaultValue={values.Rt} label="Rt" name="Rt" />
                <FieldInput defaultValue={values.Rw} label="Rw" name="Rw" />
              </Flex>
              <Flex w="100%" direction={{ lg: "row", base: "column" }}>
                <FieldInput
                  defaultValue={values.Alamat}
                  label="Alamat"
                  name="Alamat"
                />
                <FieldInput
                  defaultValue={values.KodePos}
                  label="Kode Pos"
                  name="KodePos"
                />
              </Flex>

              <Flex justifyContent="space-between" mt="4">
                <Button as={Link} to="../daftar-guru">
                  Kembali
                </Button>
                <Button
                  isLoading={loading}
                  type="submit"
                  isDisabled={!dirty}
                  colorScheme="blue"
                >
                  Simpan
                </Button>
              </Flex>
            </Form>
          </Flex>
        );
      }}
    </Formik>
  );
};
