import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
  Icon,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { AiOutlineRight, AiFillHome } from "react-icons/ai";
import { useState } from "react";
import { AddAdmin } from "./form";

export const FormAdmin = () => {
  const [active, setActive] = useState("Data Siswa");

  return (
    <Flex w="90%" minH="90vh" gap="1" direction="column">
      <Flex
        direction="column"
        gap="5"
        position="sticky"
        top="10vh"
        zIndex="10"
        bgColor="gray.200"
        pb="4"
      >
        <Flex
          alignItems="center"
          gap={{ md: "4", base: "2" }}
          bgColor="gray.300"
          w="fit-content"
          borderRadius="md"
        >
          <Flex
            borderRadius="md"
            w="7"
            h="7"
            justifyContent="center"
            bgColor="white"
            alignItems="center"
          >
            <Icon as={AiFillHome} w="3" h="3" color="blue.500" />
          </Flex>
          <Breadcrumb
            spacing="8px"
            separator={<AiOutlineRight color="gray.500" fontSize="12px" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink fontSize="12px">Siswa</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink fontSize="12px" as={Link} to="/">
                Setting
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink fontSize="12px" mr="5">
                Tambah Admin
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Flex>
        <Heading>Tambah Admin</Heading>
      </Flex>
      <AddAdmin />
    </Flex>
  );
};
