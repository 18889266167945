const fields = [
  "JK",
  "NIS",
  "NISN",
  "NIK",
  "NoKK",
  "Kota Lahir",
  "Tgl Lahir",
  "Alamat",
  "Tingkat",
  "Kelas",
  "Kamar",
  "Program",
  "Nama Ayah",
  "No HP Ayah",
  "Nama Ibu",
  "No HP Ibu",
  "Access"
];

module.exports = { fields };
