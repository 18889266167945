import { useToast } from "@chakra-ui/react";

export const useCustomToast = () => {
  const toast = useToast({
    duration: 2000,
    position: 'bottom-left'
  });

  const customToast = ({ title, description, status }) => {
    return toast({
      title,
      description,
      status,
    });
  };

  return customToast;
};
