import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Button,
    Flex,
    Heading,
    Icon,
  } from "@chakra-ui/react";
  import { Link } from "react-router-dom";
  import { AiOutlineRight, AiFillHome } from "react-icons/ai";
  import { useState } from "react";
import { BiodataManual } from "./manual";
  
  export const FormSiswaManual = () => {
    const [active, setActive] = useState("Data Siswa");
  
    return (
      <Flex w="90%" gap="1" direction="column" minH="90vh">
        <Flex
          direction="column"
          gap="5"
          position="sticky"
          top="10vh"
          zIndex="10"
          bgColor="gray.200"
          pb="4"
        >
          <Flex
            alignItems="center"
            gap={{ md: "4", base: "2" }}
            bgColor="gray.300"
            w="fit-content"
            borderRadius="md"
          >
            <Flex
              borderRadius="md"
              w="7"
              h="7"
              justifyContent="center"
              bgColor="white"
              alignItems="center"
            >
              <Icon as={AiFillHome} w="3" h="3" color="blue.500" />
            </Flex>
            <Breadcrumb
              spacing="8px"
              separator={<AiOutlineRight color="gray.500" fontSize="12px" />}
            >
              <BreadcrumbItem>
                <BreadcrumbLink fontSize="12px">Siswa</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <BreadcrumbLink fontSize="12px" as={Link} to="../daftar-siswa">
                  Daftar Siswa
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <BreadcrumbLink fontSize="12px" mr="5">
                  Tambah Siswa Manual
                </BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
          </Flex>
          <Heading fontSize={{ md: "30px", base: "20px" }}>Tambah Siswa Manual</Heading>
        </Flex>
        <BiodataManual />
      </Flex>
    );
  };
  