import { Box, Flex, Heading } from "@chakra-ui/react";
import { MyChart } from "./chart";
import axios from "../../../helper/axios";
import { useEffect, useState } from "react";
import { BreadcrumbComp } from "../../breadCump";
import { TableReport } from "./table";

export const Statistik = () => {
  const [categories, setCategories] = useState([]);
  const [series, setSeries] = useState([]);
  const [statistik, setStatistik] = useState([]);
  const getData = async () => {
    try {
      const { data } = await axios.get("/report/statistik");
      setSeries(data?.series);
      setCategories(data?.categories);
      setStatistik(data.statistik)
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Box w="full" maxW="100%" px={{ md: 10, base: 5 }}>
      <Flex
        direction="column"
        gap="5"
        position={{ lg: "sticky", base: "none" }}
        top="10vh"
        zIndex="10"
        bgColor="gray.200"
        pb="4"
      >
        <BreadcrumbComp
          menu={"Report"}
          subMenu={[
            {
              desc: "Statistik",
              link: "",
            },
          ]}
        />
        <Flex
          justifyContent="space-between"
          flexDir={{ md: "row", base: "column" }}
          alignItems={{ md: "center", base: "start" }}
        >
          <Heading fontSize={{ md: "30px", base: "20px" }}>
            Statistik
          </Heading>
        </Flex>
      </Flex>
      <MyChart data={series} categories={categories} />
      <TableReport data={statistik} />
    </Box>
  );
};
