import {
  Avatar,
  Button,
  Flex,
  FormLabel,
  Heading,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { useCustomToast } from "../toast";
import axios from "../../../helper/axios";
import { BackButtonSiswa } from "./back";

const FieldInput = ({ label, placeholder, defaultValue, name, ...props }) => {
  return (
    <FormLabel w="inherit">
      <FormLabel fontWeight={"normal"} fontSize="14px" m="2px">
        {label}
      </FormLabel>
      <Field
        name={name}
        as={Input}
        fontSize="14px"
        defaultValue={defaultValue}
        placeholder={placeholder}
        {...props}
      />
      <ErrorMessage
        component="div"
        name={name}
        style={{ color: "red", fontSize: "12px" }}
      />
    </FormLabel>
  );
};

export const Biodata = ({
  nama,
  nisn,
  nis,
  nik,
  KotaLahir,
  TglLahir,
  jk,
  saudara,
  posisi,
  kk,
  kepalaKeluarga,
  img,
}) => {
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const customToast = useCustomToast();
  const { nps } = useParams();
  const validationSchema = Yup.object().shape({
    Nama: Yup.string().required("nama is required"),
    NISN: Yup.string()
      .required("nisn is required")
      .length(10, "NISN must be exactly 10 characters"),
    NIS: Yup.string()
      .required("nis is required")
      .length(18, "NIS must be exactly 18 characters"),
    JK: Yup.string().required("jenis kelamin is required"),
    NIK: Yup.string()
      .required("nik is required")
      .length(16, "NIK must be exactly 16 characters"),
    KotaLahir: Yup.string().required("kota lahir is required"),
    TglLahir: Yup.date().required("tanggal lahir is required"),
    NoKK: Yup.string()
      .required("no kk is required")
      .length(16, "No KK must be exactly 16 characters"),
  });

  const handleSubmit = async (value) => {
    try {
      setLoading(true);
      await axios.patch(`/students/${nps}`, value, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      customToast({
        title: "Success",
        description: "Data Updated",
        status: "success",
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
      customToast({
        title: "Warning",
        description: `${err.response.data}`,
        status: "warning",
      });
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <Formik
      initialValues={{
        Nama: nama || "",
        NISN: nisn || "",
        NIS: nis || "",
        JK: jk || "",
        NIK: nik || "",
        KotaLahir: KotaLahir || "",
        TglLahir: TglLahir || "",
        Saudara: saudara || 0,
        Posisi: posisi || 0,
        NoKK: kk || "",
        KepalaKeluarga: kepalaKeluarga || "",
      }}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(data, action) => {
        handleSubmit(data);
      }}
    >
      {({ values, dirty, setFieldValue }) => {
        return (
          <Flex direction="column" bgColor="white" p="5" borderRadius="xl">
            <Form>
              <Flex
                gap="8"
                direction={{ lg: "row", base: "column" }}
                alignItems={{ lg: "none", base: "center" }}
              >
                <Avatar
                  size="2xl"
                  src={`https://adm.api.darussalamkasomalang.com/public/${img?.url}`}
                  ml={{ lg: "5", base: "0" }}
                />
                <Flex direction="column" justifyContent="space-evenly" w="100%">
                  <FieldInput
                    placeholder="nama lengkap"
                    defaultValue={values.Nama}
                    label="Nama Lengkap"
                    name="Nama"
                  />
                  <FieldInput
                    placeholder="nisn"
                    defaultValue={values.NISN}
                    label="NISN"
                    name="NISN"
                  />
                </Flex>
              </Flex>
              <Flex w="100%" direction={{ lg: "row", base: "column" }}>
                <FieldInput
                  placeholder="nis lokal"
                  defaultValue={values.NIS}
                  label="NISM"
                  name="NIS"
                />
                <FieldInput
                  placeholder="nik"
                  defaultValue={values.NIK}
                  label="NIK"
                  name="NIK"
                />
              </Flex>
              <Flex w="100%" direction={{ lg: "row", base: "column" }}>
                <FieldInput
                  placeholder="tempat lahir"
                  defaultValue={values.KotaLahir}
                  label="Tempat lahir"
                  name="KotaLahir"
                />
                <FieldInput
                  placeholder="tanggal lahir"
                  defaultValue={values.TglLahir}
                  type="date"
                  label="Tanggal lahir"
                  name="TglLahir"
                />
              </Flex>
              <Flex direction="column" gap="2" m="4">
                <Heading fontSize="14px" fontWeight="normal">
                  Jenis Kelamin
                </Heading>
                <RadioGroup
                  name="JK"
                  fontSize="14px"
                  isDisabled
                  value={values.JK}
                >
                  <Stack spacing={5} direction="row">
                    <Radio value="L">
                      <Text fontSize="14px">Laki-laki</Text>
                    </Radio>
                    <Radio value="P">
                      <Text fontSize="14px">Perempuan</Text>
                    </Radio>
                  </Stack>
                </RadioGroup>
              </Flex>
              <Flex w="100%" direction={{ lg: "row", base: "column" }}>
                <FieldInput
                  placeholder="jumlah saudara"
                  defaultValue={values.Saudara}
                  label="Jumlah Saudara"
                  name="Saudara"
                />
                <FieldInput
                  placeholder="anak ke-"
                  defaultValue={values.Posisi}
                  label="Anak ke-"
                  name="Posisi"
                />
              </Flex>
              <Flex w="100%" direction={{ lg: "row", base: "column" }}>
                <FieldInput
                  placeholder="no kartu keluarga"
                  defaultValue={values.NoKK}
                  label="No Kartu Keluarga"
                  name="NoKK"
                />
                <FieldInput
                  placeholder="nama kepala keluarga"
                  defaultValue={values.KepalaKeluarga}
                  label="Nama Kepala Keluarga"
                  name="KepalaKeluarga"
                />
              </Flex>
              <Flex justifyContent="space-between" mt="4">
                <BackButtonSiswa />
                <Button
                  isLoading={loading}
                  type="submit"
                  isDisabled={!dirty}
                  colorScheme="blue"
                >
                  Simpan
                </Button>
              </Flex>
            </Form>
          </Flex>
        );
      }}
    </Formik>
  );
};
