import {
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Td,
  Tfoot,
  Tbody,
  Flex,
  Text,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export const ReportKelas = ({ data, total }) => {
  const state = useSelector((state) => state.userSlice.value);
  return (
    <Flex flexDir="column" flex={{ md: 1, base: 'none' }} borderRadius="md" overflow="hidden">
      <Flex justify="center" p={3} bgColor="white">
        <Text fontWeight="bold">Rekap Kelas</Text>
      </Flex>
      <TableContainer bgColor="white" px={3} borderRadius="md">
        <Table variant="simple" size='sm'>
          <TableCaption>Tahun ajaran {state.tahun_pelajaran}</TableCaption>
          <Thead>
            <Tr>
              <Th>Kelas</Th>
              <Th isNumeric>jumlah ({total} Santri)</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data?.map((item) => {
              return (
                <Tr key={item.id}>
                  <Td cursor="pointer" _hover={{ fontWeight: "bold" }}>
                    <Link to={`/daftar-kelas/${item?.id}`}>
                      {item?.ref_kela?.nama}
                    </Link>
                  </Td>
                  <Td isNumeric>{item?.jumlah_siswa} Santri</Td>
                </Tr>
              );
            })}
          </Tbody>
          <Tfoot>
            <Tr>
              <Th>Total</Th>
              <Th isNumeric>{total} Santri</Th>
            </Tr>
          </Tfoot>
        </Table>
      </TableContainer>
    </Flex>
  );
};
