import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { CiMenuKebab } from "react-icons/ci";
import axios from "../../helper/axios";
import { ModalAddWali } from "./wali";
import wali from "../../helper/wali";

const TableKamar = ({ tapel, reload, setReload }) => {
  const token = localStorage.getItem("token");
  const [data, setData] = useState([]);

  const getData = async () => {
    try {
      const { data } = await axios.get(`/room`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setData(data.kamar);
    } catch (err) {
      console.log(err);
    }
  };

  const getAbsen = async (id, kamar) => {
    try {
      const { data } = await axios.get(`/room/${id}/absen`, {
        responseType: "blob",
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      const url = window.URL.createObjectURL(new Blob([data]));
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `${kamar}.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
    window.scroll(0, 0);
  }, [reload]);

  return (
    <Box mb="30px">
      <TableContainer>
        <Table variant="simple">
          <TableCaption>daftar kamar tahun ajaran {tapel}</TableCaption>
          <Thead fontSize="14px">
            <Tr>
              <Th>NO</Th>
              <Th>Kamar</Th>
              <Th display={{ md: "table-cell", base: "none" }}>Wali Kamar</Th>
              <Th display={{ md: "table-cell", base: "none" }}>Jumlah Siswa</Th>
            </Tr>
          </Thead>
          <Tbody fontSize="14px" bgColor="white">
            {data.map((item, index) => {
              return (
                <Tr key={index}>
                  <Td maxW="5px">{index + 1}</Td>
                  <Td display={{ md: "none", base: "table-cell" }}>
                    <Flex direction="column" fontSize="14px">
                      <Text fontSize="14px" fontWeight="bold">
                        {item.ref_kamar?.nama}
                      </Text>
                      <Text fontSize="12px">{wali(item.wali_kamar)}</Text>
                      <Text fontSize="12px">{item.jumlah_siswa} siswa</Text>
                    </Flex>
                  </Td>
                  <Td display={{ md: "table-cell", base: "none" }}>
                    {item.ref_kamar?.nama}
                  </Td>
                  <Td display={{ md: "table-cell", base: "none" }}>{wali(item.wali_kamar)}</Td>
                  <Td display={{ md: "table-cell", base: "none" }}>
                    {item.jumlah_siswa} siswa
                  </Td>
                  <Td isNumeric>
                    <Menu>
                      <MenuButton
                        size={{ lg: "sm", base: "xs" }}
                        as={Button}
                        bgColor="transparent"
                      >
                        <CiMenuKebab />
                      </MenuButton>
                      <MenuList>
                        <MenuItem
                          color="blue"
                          as={Link}
                          to={`../daftar-kamar/${item?.id}`}
                        >
                          Detail
                        </MenuItem>
                        <MenuItem
                          onClick={() => getAbsen(item.id, item.ref_kamar?.nama)}
                        >
                          Unduh Absen
                        </MenuItem>
                        <MenuItem>
                          <ModalAddWali detail={item} tapel={tapel} room={item.id} reload={reload} setReload={setReload} />
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TableKamar;
