import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  MenuItem,
  Flex,
  Select,
  Text,
  Box,
} from "@chakra-ui/react";
import { Formik, Form, ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import axios from "../../../helper/axios";
import * as Yup from "yup";
import { useCustomToast } from "../../siswa/toast";

export const MoveProgram = ({ student }) => {
  const [opt, setOpt] = useState([]);
  const token = localStorage.getItem("token");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const customToast = useCustomToast();

  const getData = async () => {
    try {
      const { data } = await axios.get("/program", {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      setOpt(data.program);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async ({ program }) => {
    try {
      await axios.patch(
        "/program/move",
        { program, id: student.id },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      customToast({
        title: "Success",
        description: "Data Updated",
        status: "success",
      });
      onClose();
    } catch (err) {
      customToast({
        title: "Warning",
        description: `${err.response.data}`,
        status: "warning",
      });
      console.log(err);
      onClose();
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <MenuItem onClick={onOpen}>Ubah Program</MenuItem>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ubah Program</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{student?.siswa?.siswa_biodata?.Nama}</Text>
            <Formik
              initialValues={{
                program: "",
              }}
              validationSchema={Yup.object().shape({
                program: Yup.string().required("program is required"),
              })}
              onSubmit={(data, action) => {
                handleSubmit(data);
                action.resetForm();
              }}
            >
              {({ values, dirty, setFieldValue }) => {
                return (
                  <Flex gap="5px" m="10px 0" as={Form}>
                    <Select flex={1} disabled defaultValue={student?.programId}>
                      {opt.map((item, index) => {
                        return (
                          <option key={index} value={item?.id}>
                            {item?.ref_program?.nama}
                          </option>
                        );
                      })}
                    </Select>
                    <Box flex={1}>
                      <Select
                        placeholder=" ~~ pilih program ~~"
                        defaultValue=""
                        name="program"
                        onChange={(e) => setFieldValue("program", e.target.value)}
                      >
                        {opt.map((item, index) => {
                          return (
                            <option key={index} value={item?.id}>
                              {item?.ref_program?.nama}
                            </option>
                          );
                        })}
                      </Select>
                      <ErrorMessage
                        component="div"
                        name="program"
                        style={{ color: "red", fontSize: "12px" }}
                      />
                    </Box>

                    <Button colorScheme="blue" type="submit">
                      Submit
                    </Button>
                  </Flex>
                );
              }}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
