import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {
    username: "",
    email: "",
    tahun_pelajaran: "",
    semester: "",
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action) => {
      state.value.username = action.payload.username;
      state.value.email = action.payload.email;
      state.value.tahun_pelajaran = action.payload.tahun_pelajaran;
      state.value.semester = action.payload.semester;
    },
  },
});

export const { login } = userSlice.actions;

export default userSlice.reducer;
