import {
  Badge,
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CiMenuKebab } from "react-icons/ci";
import axios from "../../helper/axios";
import { truncateString } from "../../helper/word";
import Loading from "../loading";
import { Pagination } from "../siswa/pagination";
import { useSelector } from "react-redux";

const TableGuru = () => {
  const token = localStorage.getItem("token");
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const nowPage = searchParams.get("page") || 1;
  const search = searchParams.get("search") || "";
  const status = searchParams.get("status") || "1";
  const user = useSelector((state) => state.userSlice.value);

  const getaData = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `/teachers?page=${nowPage}&nama=${search}&aktif=${status}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      setData(data.data);
      setPage(data.totalPages);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getaData();
    window.scroll(0, 0);
  }, [nowPage, search, status]);

  if (loading) {
    return <Loading />;
  }
  return (
    <Box mb="30px">
      <TableContainer>
        <Table variant="simple">
          <TableCaption>daftar guru tahun ajaran {user.tahun_pelajaran}</TableCaption>
          <Thead fontSize="14px">
            <Tr>
              <Th>NO</Th>
              <Th display={{ md: "none", base: "table-cell" }}>NO</Th>
              <Th display={{ md: "table-cell", base: "none" }}>NPY</Th>
              <Th display={{ md: "table-cell", base: "none" }}>NAMA LENGKAP</Th>
              <Th display={{ md: "table-cell", base: "none" }}>JENIS KELAMIN</Th>
              <Th display={{ sm: "table-cell", base: "none" }}>STATUS</Th>
            </Tr>
          </Thead>
          <Tbody fontSize="14px" bgColor="white">
            {data.map((item, index) => {
              return (
                <Tr key={index}>
                  <Td maxW="5px">{100 * (nowPage - 1) + index + 1}</Td>
                  <Td display={{ md: "none", base: "table-cell" }}>
                    <Flex direction="column" fontSize="14px">
                      <Text fontSize="14px" fontWeight="bold">
                        {truncateString(item.Nama, 12)}
                      </Text>
                      <Text fontSize="12px">{item.NPY}</Text>
                      <Badge
                        display={{ sm: "none", base: "flex" }}
                        w="fit-content"
                        color={status == "1" ? "green" : "red"}
                        fontSize="10px"
                        borderRadius="md"
                      >
                        {status == "1" ? "AKTIF" : "NON AKTIF"}
                      </Badge>
                    </Flex>
                  </Td>
                  <Td display={{ md: "table-cell", base: "none" }}>
                    {item.NPY}
                  </Td>
                  <Td display={{ md: "table-cell", base: "none" }}>
                    {truncateString(item.Nama, 20)}
                  </Td>
                  <Td display={{ md: "table-cell", base: "none" }}>
                    {item.JK}
                  </Td>
                  <Td display={{ sm: "table-cell", base: "none" }}>
                    <Badge
                      w="fit-content"
                      color={status == "1" ? "green" : "red"}
                      fontSize="10px"
                      borderRadius="md"
                    >
                      {status == "1" ? "AKTIF" : "NON AKTIF"}
                    </Badge>
                  </Td>
                  <Td>
                    <Menu>
                      <MenuButton
                        size={{ lg: "sm", base: "xs" }}
                        as={Button}
                        bgColor="transparent"
                      >
                        <CiMenuKebab />
                      </MenuButton>
                      <MenuList>
                        <MenuItem
                          as={Link}
                          to={`../${item.NPY}/detail-guru?back=${
                            location.pathname
                          }&query=${location.search.replaceAll("&", "@")}`}
                        >
                          Ubah
                        </MenuItem>
                        <MenuItem
                          color="red"
                          as={Link}
                        //   to={`../${item.siswa_biodata.NPS}/mutasi-keluar`}
                        >
                          Non Aktif
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      {data.length === 0 ? null : (
        <Pagination totalPages={+page} currentPage={+nowPage || 1} />
      )}
    </Box>
  );
};

export default TableGuru;
