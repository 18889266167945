import { Button, Flex, FormLabel, Input, Select } from "@chakra-ui/react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import axios from "../../../helper/axios";
import { useCustomToast } from "../../siswa/toast";

const FieldInput = ({ label, placeholder, defaultValue, name, ...props }) => {
  return (
    <FormLabel w="inherit">
      <FormLabel fontWeight={"normal"} fontSize="14px" m="2px">
        {label}
      </FormLabel>
      <Field
        name={name}
        as={Input}
        fontSize="14px"
        defaultValue={defaultValue}
        placeholder={placeholder}
        {...props}
      />
      <ErrorMessage
        component="div"
        name={name}
        style={{ color: "red", fontSize: "12px" }}
      />
    </FormLabel>
  );
};

export const AddAdmin = () => {
  const customToast = useCustomToast();
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("username is required"),
    email: Yup.string().required("email is required"),
    password: Yup.string().required("password is required"),
    role: Yup.string().required("role is required"),
  });

  const handleSubmit = async (value) => {
    try {
      setLoading(true);
      await axios.post("/auth", value, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      customToast({
        title: "Success",
        description:
          "Admin ditambahkan, silahkan verifikasi di email yang terkirim",
        status: "success",
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
      customToast({
        title: "Warning",
        description: `${err.response.data}`,
        status: "warning",
      });
    }
  };

  return (
    <Formik
      initialValues={{
        username: "",
        email: "",
        password: "",
        role: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(data, action) => {
        handleSubmit(data);
        action.resetForm();
      }}
    >
      {({ values, dirty, setFieldValue }) => {
        return (
          <Flex direction="column" bgColor="white" p="5" borderRadius="xl">
            <Form>
              <Flex w="100%" direction={{ lg: "row", base: "column" }}>
                <FieldInput
                  label="Username"
                  name="username"
                  placeholder="username"
                />
                <FieldInput
                  label="Email"
                  name="email"
                  placeholder="email"
                  type="email"
                />
              </Flex>
              <Flex w="100%" direction={{ lg: "row", base: "column" }}>
                <FieldInput
                  label="Password"
                  name="password"
                  placeholder="password"
                />
                <FormLabel w="inherit">
                  <FormLabel fontWeight={"normal"} fontSize="14px" m="2px">
                    role
                  </FormLabel>
                  <Select
                    name="role"
                    fontSize="14px"
                    placeholder="~~ role ~~"
                    onChange={(e) => setFieldValue("role", e.target.value)}
                  >
                    <option value="guest">guest</option>
                    <option value="adm">adm</option>
                    <option value="pengajaran">pengajaran</option>
                    <option value="pengasuhan">pengasuhan</option>
                    <option value="bendahara">bendahara</option>
                    <option value="kurikulum">kurikulum</option>
                    <option value="koperasi">koperasi</option>
                    <option value="laundry">laundry</option>
                  </Select>
                  <ErrorMessage
                    component="div"
                    name="role"
                    style={{ color: "red", fontSize: "12px" }}
                  />
                </FormLabel>
              </Flex>
              <Flex justifyContent="flex-end">
                <Button
                  isLoading={loading}
                  w="100px"
                  mr="3"
                  type="submit"
                  colorScheme="blue"
                  size={{ lg: "sm", base: "xs" }}
                >
                  Submit
                </Button>
              </Flex>
            </Form>
          </Flex>
        );
      }}
    </Formik>
  );
};
