import { Box, Flex } from "@chakra-ui/react";
import { Navbar } from "../components/navbar";
import { Sidebar } from "../components/sidebar";
import { Outlet } from "react-router-dom";

export const HomePage = () => {
  return (
    <Flex bgColor="gray.200">
      <Sidebar />
      <Box w="100%">
        <Flex justifyContent="center" position="sticky" top="0px" zIndex="20">
          <Navbar />
        </Flex>
        <Flex justifyContent="center" minH='90vh'>
          <Outlet />
        </Flex>
      </Box>
    </Flex>
  );
};
