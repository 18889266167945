import {
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AiOutlineUser } from "react-icons/ai";
import { MenuMobile } from "../sidebar/menuMobile";

export const Navbar = () => {
  const data = useSelector((state) => state.userSlice.value);
  const navigate = useNavigate();

  const onLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };
  return (
    <Flex
      h="10vh"
      alignItems="center"
      justifyContent={{ xl: 'flex-end', base: 'space-between' }}
      position="sticky"
      top="0px"
      zIndex="20"
      bgColor="gray.200"
      w="90%"
    >
      <MenuMobile />
      <Menu>
        <MenuButton
          _hover={{ bgColor: "green.100" }}
          borderRadius="2xl"
          p="2"
        >
          <Flex alignItems="center" gap="10px" cursor="pointer">
            <Flex
              w="40px"
              h="40px"
              borderRadius="50%"
              bg="blue.300"
              justifyContent="center"
              alignItems="center"
            >
              <Icon as={AiOutlineUser} w="5" h="5" color="white" />
            </Flex>
            <Text fontSize="14px" display={{md: 'flex', base: 'none'}}>{data.username}</Text>
          </Flex>
        </MenuButton>
        <MenuList>
          <MenuItem key={1}>Profile</MenuItem>
          <MenuItem key={2} onClick={onLogout}>
            Logout
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
};
