import { Button, Flex, FormLabel, Input, Select, Text } from "@chakra-ui/react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import axios from "../../../helper/axios";
import Loading from "../../loading";
import { useCustomToast } from "../toast";

const FieldInput = ({ label, placeholder, name, ...props }) => {
  return (
    <FormLabel w="inherit">
      <FormLabel fontWeight={"normal"} fontSize="14px" m="2px">
        {label}
      </FormLabel>
      <Field name={name}>
        {({ field }) => (
          <Input
            fontSize="14px"
            placeholder={placeholder}
            {...props}
            {...field}
          />
        )}
      </Field>
      <ErrorMessage
        component="div"
        name={name}
        style={{ color: "red", fontSize: "12px" }}
      />
    </FormLabel>
  );
};

export const FormMutasi = () => {
  const customToast = useCustomToast();
  const navigate = useNavigate();
  const { nps } = useParams();
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const [data, setData] = useState({});
  const [initialValues, setInitialValues] = useState({});

  const validationSchema = Yup.object().shape({
    Nama: Yup.string().required("Nama is required"),
    NISN: Yup.string().required("NISN is required"),
    Sekolah: Yup.string().required("sekolah tujuan is required"),
    Alasan: Yup.string().required("alasan is required"),
  });

  const getData = async () => {
    try {
      const { data } = await axios.get(`/students/${nps}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setInitialValues({
        Nama: data?.data?.siswa_biodata?.Nama || "",
        NISN: data?.data?.NISN || "",
        Sekolah: "",
        Alasan: "",
      });
      setData(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (value) => {
    try {
      setLoading(true);
      await axios.patch(`/students/${nps}/mutasi`, value, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      customToast({
        title: "Success",
        description: "Data Added",
        status: "success",
      });
      navigate("../daftar-siswa");
    } catch (err) {
      console.log(err);
      setLoading(false);
      customToast({
        title: "Warning",
        description: `${err.response.data}`,
        status: "warning",
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (!data.siswa_biodata?.Nama) {
    return <Loading />;
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(data, action) => {
        handleSubmit(data);
      }}
    >
      {({ values, dirty, setFieldValue }) => {
        return (
          <Flex
            bgColor="white"
            direction={{ md: "row", base: "column" }}
            p="5"
            borderRadius="xl"
            as={Form}
          >
            <Flex flex={1} direction="column">
              <FieldInput
                isDisabled
                label="Nama Siswa"
                name="Nama"
                placeholder="nama"
              />
              <FieldInput
                isDisabled
                label="NISN"
                name="NISN"
                placeholder="NISN"
              />
            </Flex>
            <Flex flex={1} direction="column">
              <FieldInput
                label="Sekolah Tujuan"
                name="Sekolah"
                placeholder="sekolah tujuan"
              />
              <FieldInput label="Alasan" name="Alasan" placeholder="alasan" />
              <Flex justifyContent="flex-end">
                <Button
                  isLoading={loading}
                  w="100px"
                  mr="3"
                  type="submit"
                  colorScheme="blue"
                  size={{ lg: "sm", base: "xs" }}
                  isDisabled={!dirty}
                >
                  Submit
                </Button>
              </Flex>
            </Flex>
          </Flex>
        );
      }}
    </Formik>
  );
};
