import { Flex, Image } from "@chakra-ui/react";
import { MenuButton } from "./menu";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo_wide.png"

export const Sidebar = () => {
  return (
    <Flex
      h="100vh"
      w="20vw"
      bgColor='white'
      display={{ xl: "flex", base: "none" }}
      flexDirection="column"
      position="sticky"
      top="0px"
    >
      <Flex
        w="full"
        h="15vh"
        justifyContent="center"
        alignItems="center"
        as={Link}
        to="/"
      >
        <Image
          src={Logo}
          w="15vw"
        />
      </Flex>
      <Flex>
        <MenuButton />
      </Flex>
    </Flex>
  );
};
