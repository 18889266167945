import {
  Checkbox,
  Stack,
  Button,
  Grid,
  Flex,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { fields } from "./fields";

export const ExportForm = ({ onExport, loading }) => {
  const [selectedFields, setSelectedFields] = useState([]);

  const handleFieldChange = (field) => {
    setSelectedFields((prevFields) =>
      prevFields.includes(field)
        ? prevFields.filter((f) => f !== field)
        : [...prevFields, field]
    );
  };

  const handleExport = () => {
    onExport(selectedFields);
    console.log(selectedFields);
    setSelectedFields([]);
  };

  return (
    <Stack spacing={3}>
      <Grid templateColumns={"repeat(2, 1fr)"} gap={3}>
        {fields.map((field) => (
          <Checkbox
            key={field}
            isChecked={selectedFields.includes(field)}
            onChange={() => handleFieldChange(field)}
          >
            {field}
          </Checkbox>
        ))}
      </Grid>
      <Text fontSize={'10px'} color={'red'}>* Urutan kolom mengikuti urutan ceklis</Text>
      <Flex align={"center"} justify={"space-between"} mb={5}>
        <Button
          size={{ lg: "sm", base: "xs" }}
          colorScheme="blue"
          onClick={handleExport}
          disabled={loading}
          isLoading={loading}
          loadingText="Loading ..."
        >
          Export Data
        </Button>
      </Flex>
    </Stack>
  );
};
