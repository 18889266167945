import {
  Box,
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ModalAdd } from "./add";
import { BreadcrumbComp } from "../breadCump";
import TableKamar from "./table";

export const DaftarKamar = () => {
  const data = useSelector((state) => state.userSlice.value);
  const [reload, setReload] = useState(false);

  return (
    <Flex w="90%" gap="5" direction="column">
      <Flex
        direction="column"
        gap="5"
        position={{ lg: "sticky", base: "none" }}
        top="10vh"
        zIndex="10"
        bgColor="gray.200"
        pb="4"
      >
        <BreadcrumbComp
          menu={"Kamar"}
          subMenu={[
            {
              desc: "Daftar Kamar",
              link: "",
            },
          ]}
        />
        <Flex justifyContent="space-between" alignItems="center">
          <Heading fontSize={{ md: "30px", base: "20px" }}>
            Daftar Kamar
          </Heading>
          <Flex gap="3">
            <ModalAdd data={data} reload={reload} setReload={setReload} />
          </Flex>
        </Flex>
        <Flex
          gap="2"
          direction={{ lg: "row", base: "column" }}
          alignItems="center"
          justifyContent="space-between"
          borderTop="1px solid gray"
          borderBottom="1px solid gray"
          p="5"
        >
          <Flex fontSize="14px" alignItems="center" gap="2">
            <Text>
              Semester : <b>{data.semester}</b>
            </Text>
          </Flex>
          <Flex fontSize="14px" alignItems="center" gap="2">
            <Text>Tahun Ajaran </Text>
            <Box bgColor="green.100" p="2" borderRadius="xl">
              {data?.tahun_pelajaran}
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <TableKamar tapel={data.tahun_pelajaran} reload={reload} setReload={setReload} />
    </Flex>
  );
};
