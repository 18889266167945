import { useEffect, useState } from "react";
import { useCustomToast } from "../siswa/toast";
import axios from "../../helper/axios";

const {
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Input,
} = require("@chakra-ui/react");

export const ModalAddWali = ({ detail, tapel, room, reload, setReload }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [opt, setOpt] = useState([]);
  const [search, setSearch] = useState("");
  const token = localStorage.getItem("token");
  const customToast = useCustomToast();

  const getData = async () => {
    try {
      const { data } = await axios.get(`/teachers/opt?nama=${search}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setOpt(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (room, NPY) => {
    try {
      await axios.patch(
        "/room/wali",
        { room, NPY },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      customToast({
        title: "Success",
        description: "Data Updated",
        status: "success",
      });
        setReload(!reload);
      onClose();
    } catch (err) {
      console.log(err);
      customToast({
        title: "Warning",
        description: `${err.response.data}`,
        status: "warning",
      });
    }
  };

  useEffect(() => {
    getData();
  }, [search]);

  return (
    <>
      <Text onClick={onOpen}>Wali Kamar</Text>

      <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ubah Wali Kamar</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="10px">
              anda akan mengubah wali kamar di kamar{" "}
              <b>{detail.ref_kamar?.nama}</b> tahun pelajaran {tapel}
            </Text>
            <Flex direction="column" margin="10px 0">
              <Input
                type="search"
                placeholder="cari nama"
                onChange={(e) => setSearch(e.target.value)}
              />
              <Flex w="100%" direction="column" gap="2" m="10px 0">
                {opt.map((item, index) => {
                  return (
                    <Flex
                      key={index}
                      w="100%"
                      justifyContent="space-between"
                      alignItems="center"
                      border="1px solid"
                      borderColor="gray.200"
                      borderRadius="md"
                      h="40px"
                      p="0 5px"
                    >
                      <Text fontSize="12px">{item?.Display || ""}</Text>
                      <Button
                        size="sm"
                        colorScheme="blue"
                        onClick={() => handleSubmit(room, item?.NPY)}
                      >
                        Change
                      </Button>
                    </Flex>
                  );
                })}
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
