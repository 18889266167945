import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./styles.css";

// import required modules
import {
  Keyboard,
  Pagination,
  EffectCoverflow,
  Autoplay,
} from "swiper/modules";
import { CardInfo } from "../dashboard/info";
import { LiaFemaleSolid, LiaMaleSolid } from "react-icons/lia";
import { Flex } from "@chakra-ui/react";
export default function CaraouselReport({ report }) {
  return (
    <>
      <Flex display={{ md: 'none', base: 'flex' }}>
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          keyboard={{
            enabled: true,
          }}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Keyboard, EffectCoverflow, Pagination, Autoplay]}
          pagination={true}
          className="mySwiper"
        >
          <SwiperSlide key={1}>
            <CardInfo
              icon={LiaMaleSolid}
              heading={report?.siswa?.Mts_Pa}
              t2="Jumlah Mts Putra"
              c1="blue.400"
              c2="blue.100"
            />
          </SwiperSlide>
          <SwiperSlide key={2}>
            <CardInfo
              icon={LiaFemaleSolid}
              heading={report?.siswa?.Mts_Pi}
              t2="Jumlah Mts Putri"
              c1="red.400"
              c2="red.100"
            />
          </SwiperSlide>
          <SwiperSlide key={3}>
            <CardInfo
              icon={LiaMaleSolid}
              heading={report?.siswa?.Ma_Pa}
              t2="Jumlah Ma Putra"
              c1="blue.400"
              c2="blue.100"
            />
          </SwiperSlide>
          <SwiperSlide key={4}>
            <CardInfo
              icon={LiaFemaleSolid}
              heading={report?.siswa?.Ma_Pi}
              t2="Jumlah Ma Putri"
              c1="red.400"
              c2="red.100"
            />
          </SwiperSlide>
        </Swiper>
      </Flex>
    </>
  );
}
