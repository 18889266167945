import * as Yup from "yup";
import { Formik, Form, ErrorMessage } from "formik";
import Axios from "axios";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { useParams, Link } from "react-router-dom";
import { useState } from "react";
import { useCustomToast } from "../toast";
import axios from "../../../helper/axios";
import { BackButtonSiswa } from "./back";

const {
  Flex,
  Text,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Input,
  Icon,
  Box,
  Grid,
  Image,
} = require("@chakra-ui/react");

const FieldImage = ({ desc, folder, img, reload, setReload }) => {
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const customToast = useCustomToast();
  const token = localStorage.getItem("token");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleSubmit = async (data) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", data[folder]);

      await axios.post(`/drive/${folder}/${params.nps}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: `Bearer ${token}`,
        },
      });
      setReload(!reload);
      setLoading(false);
      onClose();
      customToast({
        title: "Success",
        description: "File Uploaded",
        status: "success",
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
      customToast({
        title: "Warning",
        description: `${err.response.data}`,
        status: "warning",
      });
    }
  };
  return (
    <Flex direction="column" gap="2" cursor="pointer">
      {img === "" ? (
        <>
          <Text fontSize="14px">{desc}</Text>
          <Flex
            justifyContent="center"
            alignItems="center"
            borderRadius="xl"
            w="150px"
            h="150px"
            border="1px dashed gray"
            onClick={onOpen}
          >
            +
          </Flex>
        </>
      ) : (
        <>
          <Text
            fontSize="14px"
            as={Link}
            to={`https://adm.api.darussalamkasomalang.com/public/${img.url}`}
            target="blank"
            cursor="pointer"
            color="blue.500"
          >
            {desc}
          </Text>
          <Flex
            justifyContent="center"
            alignItems="center"
            borderRadius="xl"
            w="150px"
            h="150px"
            border="1px dashed gray"
            onClick={onOpen}
          >
            <Image
              borderRadius="xl"
              w="100%"
              h="100%"
              objectFit="cover"
              src={`https://adm.api.darussalamkasomalang.com/public/${img?.url}`}
            />
          </Flex>
        </>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload {desc}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={{
                [folder]: "",
              }}
              validationSchema={Yup.object({
                [folder]: Yup.mixed()
                  .test(
                    "fileSize",
                    "File harus dibawah 1MB",
                    (value) => !value || (value && value.size <= 1024 * 1024)
                  )
                  .required(`${folder} is required`),
              })}
              onSubmit={(data, action) => {
                handleSubmit(data);
                action.resetForm();
              }}
            >
              {({ values, dirty, setFieldValue }) => {
                return (
                  <Flex
                    as={Form}
                    justifyContent="center"
                    alignItems="start"
                    gap="2"
                    mb="2"
                  >
                    <Box>
                      <Input
                        type="file"
                        name={folder}
                        accept="image/png, image/jpeg, image/jpg"
                        onChange={(e) => {
                          setFieldValue(folder, e.target.files[0]);
                        }}
                      />
                      <ErrorMessage
                        component="div"
                        name={folder}
                        style={{ color: "red", fontSize: "12px" }}
                      />
                    </Box>
                    <Button
                      colorScheme="blue"
                      type="submit"
                      isLoading={loading}
                    >
                      <Icon as={AiOutlineCloudUpload} w="5" h="5" />
                    </Button>
                  </Flex>
                );
              }}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export const BerkasSiswa = ({
  pas_img,
  kk_img,
  akta_img,
  ktp_ayah_img,
  ktp_ibu_img,
  ktp_wali_img,
  kesehatan_img,
  pernyataan_wali_img,
  pernyataan_siswa_img,
  reload,
  setReload,
}) => {
  const data = [
    { desc: "Pas Foto", folder: "pas", img: pas_img || "" },
    { desc: "Kartu Keluarga", folder: "kk", img: kk_img || "" },
    { desc: "Akta", folder: "akta", img: akta_img || "" },
    { desc: "Ktp Ayah", folder: "ktp-ayah", img: ktp_ayah_img || "" },
    { desc: "Ktp Ibu", folder: "ktp-ibu", img: ktp_ibu_img || "" },
    { desc: "Ktp Wali", folder: "ktp-wali", img: ktp_wali_img || "" },
    { desc: "Kesehatan", folder: "kesehatan", img: kesehatan_img || "" },
    {
      desc: "Pernyataan Siswa",
      folder: "pernyataan-siswa",
      img: pernyataan_siswa_img || "",
    },
    {
      desc: "Pernyataan Wali",
      folder: "pernyataan-wali",
      img: pernyataan_wali_img || "",
    },
  ];

  return (
    <Flex direction="column" bgColor="white" p="5" borderRadius="xl">
      <Heading fontSize="25px" m="4">
        Alamat
      </Heading>
      <Grid
        borderTop="1px solid"
        borderColor="gray.200"
        justifyItems="center"
        templateColumns={{
          lg: "repeat(4, 1fr)",
          md: "repeat(3, 1fr)",
          sm: "repeat(2, 1fr)",
          base: "repeat(1, 1fr)",
        }}
        pt="5"
        gap={3}
      >
        {data.map((item, index) => {
          return (
            <FieldImage
              key={index}
              desc={item.desc}
              folder={item.folder}
              img={item.img}
              reload={reload}
              setReload={setReload}
            />
          );
        })}
      </Grid>
      <Flex justifyContent="space-between" mt="4">
        <BackButtonSiswa />
      </Flex>
    </Flex>
  );
};
