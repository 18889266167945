import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Image,
  Select,
} from "@chakra-ui/react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { login } from "../redux/userSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCustomToast } from "../components/siswa/toast";
import { useEffect, useState } from "react";
import Logo from "../assets/logo_wide.png";
import axios from "../helper/axios";

export const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("email is required"),
    password: Yup.string().required("password is required"),
    tahun_pelajaran: Yup.string().required("tahun pelajaran is required"),
  });
  const customToast = useCustomToast();
  const [loading, setLoading] = useState(false);
  const [opt, setOpt] = useState([]);

  const getData = async () => {
    try {
      const { data } = await axios.get(
        "/options/tahun-pelajaran"
      );
      setOpt(data.tahun_pelajaran)
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (data) => {
    try {
      const result = await axios.post(
        "/auth/login",
        data
      );

      dispatch(
        login({
          username: result.data.isAccountExist.username,
          email: result.data.isAccountExist.email,
          tahun_pelajaran: result.data.tapel.tahun_pelajaran,
          semester: result.data.tapel.semester,
        })
      );
      customToast({
        title: "Success",
        description: "Login berhasil !",
        status: "success",
      });
      localStorage.setItem("token", result.data.token);
      navigate("/");
    } catch (err) {
      console.log(err);
      setLoading(false);
      customToast({
        title: "Warning",
        description: `${err.response.data}`,
        status: "warning",
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        tahun_pelajaran: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(data, action) => {
        handleSubmit(data);
        action.resetForm();
      }}
    >
      {({ values, dirty, setFieldValue }) => {
        return (
          <Flex
            p={8}
            flex={1}
            alignItems={"center"}
            h="100vh"
            justifyContent={"center"}
          >
            <Stack as={Form} spacing={4} w={"full"} maxW={"md"}>
              <Flex w="full" justifyContent="center">
                <Image src={Logo} w="150px" />
              </Flex>
              <Heading fontSize={"2xl"}>Sign in to your account</Heading>
              <FormControl id="email">
                <FormLabel>Email address</FormLabel>
                <Field as={Input} type="email" name="email" />
                <ErrorMessage
                  component="div"
                  name="email"
                  style={{ color: "red", fontSize: "12px" }}
                />
              </FormControl>
              <FormControl id="password">
                <FormLabel>Password</FormLabel>
                <Field as={Input} type="password" name="password" />
                <ErrorMessage
                  component="div"
                  name="password"
                  style={{ color: "red", fontSize: "12px" }}
                />
              </FormControl>
              <FormControl w="inherit">
                <FormLabel>
                  Tahun Pelajaran
                </FormLabel>
                <Select
                  placeholder=" ~~ tahun pelajaran ~~"
                  defaultValue=""
                  name="tahun_pelajaran"
                  onChange={(e) =>
                    setFieldValue("tahun_pelajaran", e.target.value)
                  }
                >
                  {opt.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.tahun_pelajaran} - {item.semester}
                      </option>
                    );
                  })}
                </Select>
                <ErrorMessage
                  component="div"
                  name="tahun_pelajaran"
                  style={{ color: "red", fontSize: "12px" }}
                />
              </FormControl>
              <Stack spacing={6}>
                <Button
                  colorScheme={"blue"}
                  isLoading={loading}
                  variant={"solid"}
                  type="submit"
                >
                  Sign in
                </Button>
              </Stack>
            </Stack>
          </Flex>
        );
      }}
    </Formik>
  );
};
