import { Button } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";

export const BackButtonSiswa = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const back = searchParams.get("back")
  const query = searchParams.get("query") || ""
  return (
    <Button
      as={Link}
      to={back ? `${back}${query.replaceAll("@", "&")}` : "../daftar-siswa"}
    >
      Kembali
    </Button>
  );
};
