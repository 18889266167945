import {
  Button,
  Flex,
  Heading,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Biodata } from "./biodata";
import { BreadcrumbComp } from "../../breadCump";

export const FormSiswa = () => {
  return (
    <Flex w="90%" gap="1" direction="column" minH="90vh">
      <Flex
        direction="column"
        gap="5"
        position="sticky"
        top="10vh"
        zIndex="10"
        bgColor="gray.200"
        pb="4"
      >
        <Flex
          alignItems="center"
          gap={{ md: "4", base: "2" }}
          bgColor="gray.300"
          w="fit-content"
          borderRadius="md"
        >
          <BreadcrumbComp
            menu={"Siswa"}
            subMenu={[
              {
                desc: "Daftar Siswa",
                link: "../daftar-siswa",
              },
              {
                desc: "Tambah Siswa",
                link: "",
              },
            ]}
          />
        </Flex>
        <Flex gap="3" justifyContent='space-between'>
          <Heading fontSize={{ md: "30px", base: "20px" }}>
            Tambah Siswa
          </Heading>
          <Button
            size={{ lg: "sm", base: "xs" }}
            colorScheme="blue"
            as={Link}
            to="/tambah-siswa-manual"
          >
            Tambah manual
          </Button>
        </Flex>
      </Flex>
      <Biodata />
    </Flex>
  );
};
