import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import Banner1 from "../../assets/banner1.jpg"
import Banner2 from "../../assets/banner2.jpg"
import Banner3 from "../../assets/banner3.jpg"

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./styles.css";

// import required modules
import {
  Keyboard,
  Pagination,
  EffectCoverflow,
  Autoplay,
} from "swiper/modules";
import { Flex } from "@chakra-ui/react";

export default function Caraousel() {
  return (
    <>
      <Flex>
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          keyboard={{
            enabled: true,
          }}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          navigation={true}
          pagination={true}
          modules={[Keyboard, EffectCoverflow, Pagination, Autoplay]}
          className="mySwiper"
        >
          <SwiperSlide key={1}>
            <img src={Banner1} />
          </SwiperSlide>
          <SwiperSlide key={2}>
            <img src={Banner2} />
          </SwiperSlide>
          <SwiperSlide key={3}>
            <img src={Banner3} />
          </SwiperSlide>
        </Swiper>
      </Flex>
    </>
  );
}
