import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
  Icon,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { AiOutlineRight, AiFillHome } from "react-icons/ai";
import { useSelector } from "react-redux";
import { ModalAdd } from "./add";
import TableKelas from "./table";

export const DaftarKelas = () => {
  const data = useSelector((state) => state.userSlice.value);
  const [reload, setReload] = useState(false);

  return (
    <Flex w="90%" gap="5" direction="column">
      <Flex
        direction="column"
        gap="5"
        position={{ lg: "sticky", base: "none" }}
        top="10vh"
        zIndex="10"
        bgColor="gray.200"
        pb="4"
      >
        <Flex
          alignItems="center"
          gap={{ md: "4", base: "2" }}
          bgColor="gray.300"
          w="fit-content"
          borderRadius="md"
        >
          <Flex
            borderRadius="md"
            w="7"
            h="7"
            justifyContent="center"
            bgColor="white"
            alignItems="center"
          >
            <Icon as={AiFillHome} w="3" h="3" color="blue.500" />
          </Flex>
          <Breadcrumb
            spacing="8px"
            separator={<AiOutlineRight color="gray.500" fontSize="12px" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink fontSize="12px">Kelas</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink fontSize="12px" mr="5">
                Daftar Kelas
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Flex>
        <Flex justifyContent="space-between" alignItems="center">
          <Heading fontSize={{ md: "30px", base: "20px" }}>
            Daftar Kelas
          </Heading>
          <Flex gap="3">
            <ModalAdd data={data} reload={reload} setReload={setReload} />
          </Flex>
        </Flex>
        <Flex
          gap="2"
          direction={{ lg: "row", base: "column" }}
          alignItems="center"
          justifyContent="space-between"
          borderTop="1px solid gray"
          borderBottom="1px solid gray"
          p="5"
        >
          <Flex fontSize="14px" alignItems="center" gap="2">
            <Text>
              Semester : <b>{data.semester}</b>
            </Text>
          </Flex>
          <Flex fontSize="14px" alignItems="center" gap="2">
            <Text>Tahun Ajaran </Text>
            <Box bgColor="green.100" p="2" borderRadius="xl">
              {data?.tahun_pelajaran}
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <TableKelas tapel={data.tahun_pelajaran} reload={reload} setReload={setReload} />
    </Flex>
  );
};
