import { RxHamburgerMenu } from "react-icons/rx";
import { CgClose } from "react-icons/cg";
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  IconButton,
  Image,
  useDisclosure,
} from "@chakra-ui/react";
import { MenuButton } from "./menu";
import { Link } from 'react-router-dom'
import Logo from "../../assets/logo_wide.png"

export const MenuMobile = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <IconButton
        bgColor="transparent"
        color="blue.500"
        size={"md"}
        icon={isOpen ? <CgClose /> : <RxHamburgerMenu />}
        aria-label={"Open Menu"}
        display={{ xl: "none" }}
        onClick={isOpen ? onClose : onOpen}
        fontSize="20px"
      />
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />

          <DrawerBody>
            <Flex
              h="100vh"
              w="200px"
              flexDirection="column"
              position="sticky"
              top="0px"
            >
              <Flex
                w="full"
                h="15vh"
                justifyContent="center"
                alignItems="center"
                as={Link}
                to="/"
              >
                <Image
                  src={Logo}
                  w="150px"
                />
              </Flex>
              <Flex>
                <MenuButton />
              </Flex>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
