import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalHeader,
  Button,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Flex,
  Icon,
} from "@chakra-ui/react";
import { ExportForm } from "./form";
import { AiOutlineCloudDownload } from "react-icons/ai";
import axios from "../../../helper/axios";
import { DownloadAppsheet } from "./appsheet";
import { DownloadSiswa } from "./donwload";
import { useState } from "react";
import { useCustomToast } from "../toast";

export const ModalExport = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const customToast = useCustomToast();
  const handleExport = async (fields) => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        "/students/excel-download",
        { fields },
        {
          responseType: "blob",
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `daftar-santri.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      setLoading(false);
      customToast({
        title: "Success",
        description: "Berhasil Export data",
        status: "success",
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
      customToast({
        title: "Error",
        description: "Gagal Export data",
        status: "error",
      });
    }
  };

  return (
    <>
      <Button
        as={Flex}
        onClick={onOpen}
        size={{ lg: "sm", base: "xs" }}
        colorScheme="blue"
        gap={2}
        cursor={"pointer"}
      >
        Export
        <Icon as={AiOutlineCloudDownload} w="5" h="5" />
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size={"3xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            as={Flex}
            flexWrap={"wrap"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            Export Data
            <Flex gap={2} mr={10}>
              <DownloadAppsheet />
              <DownloadSiswa />
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ExportForm onExport={handleExport} loading={loading} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
