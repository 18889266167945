import axios from 'axios';

// const BASE_URL = 'http://localhost:2000/api'
// const BASE_URL = 'https://adm.api.darussalamkasomalang.sch.id/api'
// const BASE_URL = 'https://pika-artistic-basically.ngrok-free.app/api'
const BASE_URL = 'https://adm.api.darussalamkasomalang.com/api'

export default axios.create({
  baseURL: BASE_URL,
});
