import {
  Box,
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import axios from "../../../helper/axios";
import { ModalAddSiswa } from "./addSiswa";
import TableKelasSiswa from "./table";
import { BreadcrumbComp } from "../../breadCump";

export const DetailKamar = () => {
  const data = useSelector((state) => state.userSlice.value);
  const [reload, setReload] = useState(false);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [detail, setDetail] = useState({});
  const { kamar } = useParams();

  const getDetail = async () => {
    try {
      const { data } = await axios.get(`/room/${kamar}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setDetail(data.kamar?.ref_kamar);
    } catch (err) {
      navigate("/daftar-kamar");
      console.log(err);
    }
  };

  useEffect(() => {
    getDetail();
  }, []);

  return (
    <Flex w="90%" gap="5" direction="column">
      <Flex
        direction="column"
        gap="5"
        position={{ lg: "sticky", base: "none" }}
        top="10vh"
        zIndex="10"
        bgColor="gray.200"
        pb="4"
      >
        <BreadcrumbComp 
          menu={"Kamar"} 
          subMenu={[
            {
              desc: "Daftar Kamar",
              link: "../daftar-kamar"
            },
            {
              desc: detail?.nama,
              link: ""
            }
          ]} 
        />
        <Flex justifyContent="space-between" alignItems="center">
          <Heading fontSize={{ md: "30px", base: "20px" }}>
            Kamar {detail?.nama}
          </Heading>
          <Flex gap="3">
            <ModalAddSiswa
              data={data}
              detail={detail}
              reload={reload}
              setReload={setReload}
            />
          </Flex>
        </Flex>
        <Flex
          gap="2"
          direction={{ lg: "row", base: "column" }}
          alignItems="center"
          justifyContent="space-between"
          borderTop="1px solid gray"
          borderBottom="1px solid gray"
          p="5"
        >
          <Flex fontSize="14px" alignItems="center" gap="2">
            <Text>
              Semester : <b>{data.semester}</b>
            </Text>
          </Flex>
          <Flex fontSize="14px" alignItems="center" gap="2">
            <Text>Tahun Ajaran </Text>
            <Box bgColor="green.100" p="2" borderRadius="xl">
              {data?.tahun_pelajaran}
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <TableKelasSiswa
        detail={detail}
        tapel={data.tahun_pelajaran}
        reload={reload}
      />
    </Flex>
  );
};
