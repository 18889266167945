import { Flex, Heading } from "@chakra-ui/react";
import { AddGuru } from "./add";
import { BreadcrumbComp } from "../../breadCump";

export const FormGuru = () => {
  return (
    <Flex w="90%" gap="1" direction="column" minH="90vh">
      <Flex
        direction="column"
        gap="5"
        position="sticky"
        top="10vh"
        zIndex="10"
        bgColor="gray.200"
        pb="4"
      >
        <Flex
          alignItems="center"
          gap={{ md: "4", base: "2" }}
          bgColor="gray.300"
          w="fit-content"
          borderRadius="md"
        >
          <BreadcrumbComp
            menu={"Guru"}
            subMenu={[
              {
                desc: "Daftar Guru",
                link: "../daftar-guru",
              },
              {
                desc: "Tambah Guru",
                link: "",
              },
            ]}
          />
        </Flex>
        <Flex gap="3" justifyContent="space-between">
          <Heading fontSize={{ md: "30px", base: "20px" }}>
            Tambah Siswa
          </Heading>
        </Flex>
      </Flex>
      <AddGuru />
    </Flex>
  );
};
