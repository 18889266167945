import { Button, Flex, FormLabel, Input, Select } from "@chakra-ui/react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import axios from "../../../helper/axios";
import { useCustomToast } from "../toast";

const FieldInput = ({ label, placeholder, defaultValue, name, ...props }) => {
  return (
    <FormLabel w="inherit">
      <FormLabel fontWeight={"normal"} fontSize="14px" m="2px">
        {label}
      </FormLabel>
      <Field
        name={name}
        as={Input}
        fontSize="14px"
        defaultValue={defaultValue}
        placeholder={placeholder}
        {...props}
      />
      <ErrorMessage
        component="div"
        name={name}
        style={{ color: "red", fontSize: "12px" }}
      />
    </FormLabel>
  );
};
const FieldSelect = ({
  label,
  option,
  placeholder,
  setFieldValue,
  values,
  name,
  ...props
}) => {
  return (
    <FormLabel w="inherit">
      <FormLabel fontWeight={"normal"} fontSize="14px" m="2px">
        {label}
      </FormLabel>
      <Select
        placeholder={placeholder}
        defaultValue=""
        name={name}
        {...props}
        onChange={(e) => setFieldValue(name, e.target.value)}
      >
        {option.map((item, index) => {
          return (
            <option key={index} value={item.value}>
              {item.desc}
            </option>
          );
        })}
      </Select>
      <ErrorMessage
        component="div"
        name={name}
        style={{ color: "red", fontSize: "12px" }}
      />
    </FormLabel>
  );
};

export const BiodataManual = () => {
  const customToast = useCustomToast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const validationSchema = Yup.object().shape({
    Nama: Yup.string().required("nama is required"),
    NPS: Yup.string()
      .required("nps is required")
      .test("valid-code", "Kode tidak valid", (value) => {
        return /^[A-Z0-9]+\.[A-Z0-9]+\.[A-Z0-9]+$/.test(value);
      })
      .length(13, "NPS must be exactly 13 characters"),
    NISN: Yup.string()
      .required("nisn is required")
      .length(10, "NISN must be exactly 10 characters"),
    JK: Yup.string().required("jenis kelamin is required"),
    Tingkat: Yup.string().required("tingkat is required"),
    ThnMasuk: Yup.string().required("tahun masuk is required"),
  });

  const handleSubmit = async (value) => {
    try {
      console.log(value);
      setLoading(true);
      await axios.post("/students/manual", value, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      customToast({
        title: "Success",
        description: "Data Added",
        status: "success",
      });
    //   navigate("../daftar-siswa");
    } catch (err) {
      console.log(err);
      setLoading(false);
      customToast({
        title: "Warning",
        description: `${err.response.data}`,
        status: "warning",
      });
    }
  };

  return (
    <Formik
      initialValues={{
        NPS: "",
        Nama: "",
        NISN: "",
        JK: "",
        Tingkat: "",
        ThnMasuk: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(data, action) => {
        handleSubmit(data);
      }}
    >
      {({ values, dirty, setFieldValue }) => {
        return (
          <Flex direction="column" bgColor="white" p="5" borderRadius="xl">
            <Form>
              <Flex w="100%" direction={{ lg: "row", base: "column" }}>
                <FieldInput label="NPS" name="NPS" placeholder="NPS" />
                <FieldInput
                  label="Nama Lengkap"
                  name="Nama"
                  placeholder="nama lengkap"
                />
                <FieldInput label="NISN" name="NISN" placeholder="NISN" />
              </Flex>
              <Flex w="100%" direction={{ lg: "row", base: "column" }}>
                <FieldSelect
                  name="JK"
                  setFieldValue={setFieldValue}
                  values={values}
                  label="Jenis Kelamin"
                  placeholder="jenis kelamin"
                  option={[
                    { desc: "Laki-laki", value: "L" },
                    { desc: "Perempuan", value: "P" },
                  ]}
                />
                <FieldSelect
                  name="Tingkat"
                  label="Tingkat"
                  values={values}
                  setFieldValue={setFieldValue}
                  placeholder="tingkat"
                  option={[
                    { desc: "1", value: "7" },
                    { desc: "2", value: "8" },
                    { desc: "3", value: "9" },
                    { desc: "4", value: "10" },
                    { desc: "5", value: "11" },
                    { desc: "6", value: "12" },
                  ]}
                />
                <FieldSelect
                  name="ThnMasuk"
                  label="Tahun Masuk"
                  values={values}
                  setFieldValue={setFieldValue}
                  placeholder="tahun masuk"
                  option={[
                    { value: "2019", desc: "2019" },
                    { value: "2020", desc: "2020" },
                    { value: "2021", desc: "2021" },
                    { value: "2022", desc: "2022" },
                    { value: "2023", desc: "2023" },
                    { value: "2024", desc: "2024" },
                    { value: "2025", desc: "2025" },
                    { value: "2026", desc: "2026" },
                    { value: "2027", desc: "2027" },
                    { value: "2028", desc: "2028" },
                  ]}
                />
              </Flex>
              <Flex justifyContent="flex-end">
                <Button
                  isLoading={loading}
                  w="100px"
                  mr="3"
                  type="submit"
                  colorScheme="blue"
                  size={{ lg: "sm", base: "xs" }}
                >
                  Submit
                </Button>
              </Flex>
            </Form>
          </Flex>
        );
      }}
    </Formik>
  );
};
