import { Flex, Text } from "@chakra-ui/react";
import {
  AiOutlineHome,
  AiOutlineTeam,
} from "react-icons/ai";
import { LiaMaleSolid, LiaFemaleSolid } from "react-icons/lia";
import Caraousel from "../caraousel";
import { CardInfo } from "./info";
import { CardReport } from "./report";
import { useSelector } from "react-redux";
import Banner from "../../assets/dashboard-banner.png";
import axios from "../../helper/axios";
import { useEffect, useState } from "react";
import { ReportKamar } from "./kamar";
import { ReportKelas } from "./kelas";
import CaraouselReport from "../caraousel/report";
import { ReportProgram } from "./program";

export const Dashboard = () => {
  const data = useSelector((state) => state.userSlice.value);
  const token = localStorage.getItem("token");
  const [report, setReport] = useState({});
  const getReport = async () => {
    try {
      const { data } = await axios.get("/report/beranda", {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setReport(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getReport();
  }, []);

  return (
    <Flex w="90%" gap={{ md: "4", base: "2" }} direction="column">
      <Flex h="100px" bgColor="blue.200" borderRadius="xl">
        <Flex flex={1}>
          <Flex direction="column" ml="5" justifyContent="center">
            <Text fontSize={{ lg: "16px", base: "14px" }}>Selamat Datang,</Text>
            <Text
              fontSize={{ lg: "30px", base: "16px" }}
              overflowWrap="break-word"
              fontWeight="semibold"
            >
              {data?.username}
            </Text>
          </Flex>
        </Flex>
        <Flex flex={1} bgImage={Banner} />
      </Flex>
      <Caraousel />
      <CaraouselReport report={report} />
      <Flex
        gap={{ md: "4", base: "2" }}
        direction={{ lg: "row", base: "column" }}
        display={{ md: 'flex', base: 'none' }}
      >
        <CardInfo
          icon={LiaMaleSolid}
          heading={report?.siswa?.Mts_Pa}
          t2="Jumlah Mts Putra"
          c1="blue.400"
          c2="blue.100"
        />
        <CardInfo
          icon={LiaFemaleSolid}
          heading={report?.siswa?.Mts_Pi}
          t2="Jumlah Mts Putri"
          c1="red.400"
          c2="red.100"
        />
        <CardInfo
          icon={LiaMaleSolid}
          heading={report?.siswa?.Ma_Pa}
          t2="Jumlah Ma Putra"
          c1="blue.400"
          c2="blue.100"
        />
        <CardInfo
          icon={LiaFemaleSolid}
          heading={report?.siswa?.Ma_Pi}
          t2="Jumlah Ma Putri"
          c1="red.400"
          c2="red.100"
        />
      </Flex>
      <Flex
        gap={{ md: "4", base: "2" }}
        flexDir={{ md: "row", base: "column" }}
      >
        <Flex gap={{ md: "4", base: "2" }} flex={1}>
          <CardReport
            icon={AiOutlineTeam}
            heading={report?.siswa?.Total}
            t1="Jumlah Siswa Aktif"
            c1="green.300"
          />
          <CardReport
            icon={AiOutlineTeam}
            heading={report?.guru}
            t1="Jumlah Guru"
            c1="orange.300"
          />
        </Flex>
        <Flex gap={{ md: "4", base: "2" }} flex={1}>
          <CardReport
            icon={AiOutlineTeam}
            heading={report?.kelas}
            t1="Jumlah Kelas"
            c1="yellow.300"
          />
          <CardReport
            icon={AiOutlineHome}
            heading={report?.kamar}
            t1="Jumlah Kamar"
            c1="blue.300"
          />
        </Flex>
      </Flex>
      <Flex
        gap={{ md: "4", base: "2" }}
        mb={3}
        flexDir={{ base: "column", lg: "row" }}
      >
        <ReportKelas data={report?.detailKelas} total={report?.siswaKelas} />
        <ReportProgram data={report?.detailProgram} total={report?.siswaProgram} />
        <ReportKamar data={report?.detailKamar} total={report?.siswaKamar} />
      </Flex>
    </Flex>
  );
};
