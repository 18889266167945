import { Flex, Heading, Icon, Text } from "@chakra-ui/react";

export const CardInfo = ({ heading, icon, t1, t2, c1, c2 }) => {
  return (
    <Flex
      flex={{base: 'none', lg: 1 }}
      minH={{base: '100px', lg: '120px' }}
      borderLeft="5px solid"
      borderColor={c1}
      borderRadius="md"
      bgColor="white"
    >
      <Flex alignItems="center">
        <Flex
          w="50px"
          h="50px"
          borderRadius="50%"
          bg={c2}
          justifyContent="center"
          alignItems="center"
          m="4"
        >
          <Icon as={icon} w="7" h="7" color={c1} />
        </Flex>
        <Flex direction="column">
          <Heading fontSize={{base: '16px', lg: '20px' }}>{heading}</Heading>
          <Text overflowWrap="break-word" fontSize={{base: '12px', lg: '14px' }} color="gray.500">
            {t1}
          </Text>
          <Text overflowWrap="break-word" fontSize={{base: '12px', lg: '14px' }} color={c1}>
            {t2}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
