import { Flex, Icon, Text } from "@chakra-ui/react";

export const CardReport = ({ heading, icon, t1, c1 }) => {
  return (
    <Flex
      flex={1}
      h={{ base: "100px", lg: "120px" }}
      borderRadius="md"
      bgColor="white"
      cursor="pointer"
    >
      <Flex alignItems="center">
        <Flex
          p="2"
          w={{ base: "50px", md: "10%", lg: "5%" }}
          h={{ base: "50px", md: "10%", lg: "5%" }}
          minWidth="50px"
          minHeight="50px"
          borderRadius="50%"
          bg={c1}
          justifyContent="center"
          alignItems="center"
          m="4"
        >
          <Icon as={icon} w="7" h="7" color="white" />
        </Flex>
        <Flex direction="column" pr="2px">
          <Text
            overflowWrap="break-word"
            fontSize={{ base: "20px", lg: "25px" }}
            fontWeight="semibold"
          >
            {heading}
          </Text>
          <Text
            overflowWrap="break-word"
            fontSize={{ base: "12px", lg: "14px" }}
            color="gray.500"
          >
            {t1}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
